import React from 'react';
import styled from '@emotion/styled';
import {BREAKPOINTS} from '../../utils/styles/breakpoints';
import {IconButton} from '../Button/IconButton';
import {Bars} from '../Icons/Icons';
import {DesktopMenu} from './Desktop/DesktopMenu';
import {MobileMenu} from './Mobile/MobileMenu';

const Wrapper = styled.header`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  box-sizing: border-box;
  width: 100%;
`;

const MobileMenuContainer = styled.div`
  display: flex;
  ${BREAKPOINTS.MEDIUM}{
    display: none;
  }
`;

export const Header = () => {

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <Wrapper>
      <DesktopMenu/>
      <MobileMenuContainer>
        <IconButton action={() => setIsMenuOpen(true)} icon={<Bars/>}/>
        <MobileMenu open={isMenuOpen} onClose={() => setIsMenuOpen(false)}/>
      </MobileMenuContainer>
    </Wrapper>
  );
};