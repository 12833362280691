import React, {useEffect} from 'react';
import {Route, Routes, useLocation} from 'react-router';
import {Navigate, useNavigate} from 'react-router-dom';
import {Layout} from './components/Layout';
import {HomePage} from './features/HomePage/HomePage';
import {FloorPage} from './features/FloorPage/FloorPage';
import {FlatPage} from './features/FlatPage/FlatPage';
import {
  selectLoading, setHomePage,
  setLocalizationLoading,
  setProjects,
  useProjectsDispatch,
  useProjectsSelector,
} from './utils';
import Sanity from './utils/sanity';
import {useTranslation} from 'react-i18next';
import {ProjectPage} from './features/ProjectPage/ProjectPage';

export const App = () => {

  const dispatch = useProjectsDispatch();

  const {i18n} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  i18n.on('loaded', () => {
    dispatch(setLocalizationLoading(false));
  });
  i18n.on('languageChanged', (lang) => {
    document.documentElement.lang = lang;
  });

  const loading = useProjectsSelector(selectLoading);

  useEffect(() => {
    Sanity.getProjects().then(projects => {
      dispatch(setProjects(projects));
    });
    Sanity.getHomePage().then(homePage => {
      dispatch(setHomePage(homePage));
    });
  }, []);

  useEffect(() => {
    if (!location.hash) {
      return;
    }
    const section = document.querySelector(location.hash);
    if (!section) {
      return;
    }

    navigate(location.pathname, {replace: true});

    section.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [location.hash]);

  return (
    <Layout loading={loading}>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/project/:project" element={<ProjectPage/>}/>
        <Route path="/project/:project/:floor" element={<FloorPage/>}/>
        <Route path="/project/:project/:floor/:flat" element={<FlatPage/>}/>
        <Route path="*" element={<Navigate to="/"/>}/>
      </Routes>
    </Layout>
  );
};

export default App;
