import React from 'react';
import styled from '@emotion/styled';
import {ComponentLStyling, ComponentMStyling, TextStyle} from '../../../utils/styles/typography';
import {COLORS} from '../../../utils/styles/colors';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import {useTranslation} from 'react-i18next';
import {selectCurrentProject} from '../../../utils';
import {useSelector} from 'react-redux';
import {CountDown} from '../../Common/CountDown';

const Background = styled.div`
  background-color: ${COLORS.Primary50};
  padding: 16px 16px;
  ${BREAKPOINTS.MEDIUM}{
    padding: 16px 32px;
  }

  ${BREAKPOINTS.MEDIUM}{
    padding: 28px 32px;
  }

  ${BREAKPOINTS.XLARGE}{
    padding: 28px 56px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  
  padding: 24px 0;
  max-width: 1200px;
  margin: 0 auto;
`;

const HeaderText = styled.div`
  ${ComponentMStyling(TextStyle.SemiBold, COLORS.Black)}
  
  ${BREAKPOINTS.LARGE}{
    ${ComponentLStyling(TextStyle.SemiBold, COLORS.Black)}
  }
`;

export const CountDownSection = () => {

  const {t} = useTranslation();
  const currentProject = useSelector(selectCurrentProject);

  return (
    <Background>
      <Wrapper>
        <HeaderText>
          {t('დასრულებამდე დარჩენილია')}
        </HeaderText>
        <CountDown date={currentProject!.endDate}/>
      </Wrapper>
    </Background>
  );
};