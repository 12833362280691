import React, {CSSProperties, useEffect, useState} from 'react';
import {Floor, selectCurrentProject} from '../../../utils';
import {useSelector} from 'react-redux';
import styled from '@emotion/styled';
import {COLORS} from '../../../utils/styles/colors';
import {useMediaMatch} from 'rooks';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import {Button} from '../../../components/Button/Button';
import {t} from 'i18next';


const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.Primary300}77;
  box-shadow: 0px 0px 20px 0px #fff;
  
  pointer-events: auto;
  
  ${BREAKPOINTS.LARGE}{
    pointer-events: none;
  }
`;

interface Props {
  floor?: Floor;
  container?: SVGSVGElement | null,
  width?: number;
  onFloorSelected: (floor: string) => void;
}

export const FloorOverlay: React.FunctionComponent<Props> = ({floor, container, width, onFloorSelected}) => {

  const [styles, setStyles] = useState<CSSProperties>();
  const isLargeScreen = useMediaMatch(BREAKPOINTS.LARGE.replace('@media ', ''));


  const currentProject = useSelector(selectCurrentProject);

  useEffect(() => {
    if (floor && container) {
      const currentFloor = currentProject?.floors.find(a => a.floor.floor == floor.floor)!;
      const floorContainer = container!.querySelector(`[class="${currentFloor.container}"]`) as SVGSVGElement;


      const xMultiplier = container.clientWidth / container.getBBox().width;
      const yMultiplier = container.clientHeight / container.getBBox().height;

      const rect = floorContainer?.getBBox();

      if (rect) {
        setStyles({
          display: 'flex',
          left: rect.x * xMultiplier,
          top: rect.y * yMultiplier,
          width: rect.width * xMultiplier,
          height: rect.height * yMultiplier,
          transition: isLargeScreen ? 'none' : 'all 0.1s ease-in-out',
        });
        return;
      }

    }

    setStyles({display: 'none', transition: 'none'});
  }, [floor, container, width]);

  return (
    <Wrapper style={styles} id={'overlay'}>
      <Button action={() => onFloorSelected(floor!.floor)} style={{flex: 'unset'}}
              label={t('Select: {{floor}}', {floor: floor?.floor})}/>
    </Wrapper>
  );

};