import React from 'react';
import styled from '@emotion/styled/macro';
import {COLORS} from '../../../utils/styles/colors';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectHomePageData} from '../../../utils';
import {useMediaMatch} from 'rooks';
import {HeaderText} from '../styles';
import sanity from '../../../utils/sanity';

const Background = styled.div`
  background-color: ${COLORS.White};
  padding: 16px 16px;
  ${BREAKPOINTS.MEDIUM}{
    padding: 16px 32px;
  }

  ${BREAKPOINTS.MEDIUM}{
    padding: 28px 32px;
  }

  ${BREAKPOINTS.XLARGE}{
    padding: 56px 56px;
  }
`;


const ImageContainer = styled.div`
  display: flex;
  img{
    max-width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: 1200px;
  margin: 0 auto;

`;


export const FacilitiesSection = () => {

  const {t} = useTranslation();
  const data = useSelector(selectHomePageData);

  return <Background><Wrapper>
    <HeaderText>
      <Trans ns={'Common'} i18nKey={'HomePageFacilitiesHeader'}>
        <i>სპორტი</i> შენს ეზოში
      </Trans>
    </HeaderText>
    <ImageContainer>
      <img alt={data?.facilities.altText}
           src={sanity.imageUrlBuilder(data!.facilities!.asset._ref).format('png').url()}/>
    </ImageContainer>
  </Wrapper></Background>;
};