import React from 'react';
import styled from '@emotion/styled';
import {ComponentSStyling, ParagraphSStyling, ParagraphXSStyling, TextStyle} from '../../utils/styles/typography';
import {COLORS} from '../../utils/styles/colors';

const Container = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  user-select: none;
  
  ${ParagraphSStyling(TextStyle.Regular, COLORS.Primary700)}
  text-decoration: none;
  
  &:hover{
    text-decoration: underline;
  }
  
  &:active {
    text-decoration: underline;
    color: ${COLORS.Primary900};
  }
  
  i, svg{
    width: 16px;
    height: 16px;
  }
`;


export interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  label: string;
  icon: React.ReactElement;
}

export const HyperLink: React.FunctionComponent<Props> = ({icon, label, ...rest}) => {
  return (
    <Container {...rest}>
      <i>{icon}</i>
      <span>{label}</span>
    </Container>
  );
};