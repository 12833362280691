import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import styled from '@emotion/styled';
import {COLORS} from '../../utils/styles/colors';
import {
  HeadlineLStyling,
  HeadlineMStyling,
  HeadlineSStyling, HeadlineXLStyling, ParagraphLStyling, ParagraphMStyling,
  ParagraphSStyling,
  TextStyle,
} from '../../utils/styles/typography';
import {BREAKPOINTS} from '../../utils/styles/breakpoints';
import {ApartmentSection} from './components/ApartmentSection';
import {CountDownSection} from './components/CountDownSection';
import {VegetationSection} from './components/VegetationSection';
import {SunlightSection} from './components/SunlightSection';
import {FacilitiesSection} from './components/FacilitiesSection';
import {ParkingSection} from './components/ParkingSection';
import {Helmet} from "react-helmet";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  img{
    mix-blend-mode: multiply;
  }
`;

export const HomePage = () => {
  const {t} = useTranslation('Common');

  return (
    <Wrapper>
      <ApartmentSection/>
      <CountDownSection/>
      <SunlightSection/>
      <VegetationSection/>
      <FacilitiesSection/>
      <ParkingSection/>
    </Wrapper>
  );
};