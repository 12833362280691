import React from 'react';
import styled from '@emotion/styled';
import {BREAKPOINTS} from '../../utils/styles/breakpoints';
import {
  ComponentLStyling,
  ComponentMStyling,
  ParagraphMStyling,
  ParagraphSStyling, ParagraphXSStyling,
  TextStyle,
} from '../../utils/styles/typography';
import {COLORS} from '../../utils/styles/colors';
import {Trans, useTranslation} from 'react-i18next';
import {HyperLink} from '../HyperLink/HyperLink';
import {Email, Facebook, Instagram, Location, Phone, Viber, WhatsApp} from '../Icons/Icons';
import {useSelector} from 'react-redux';
import {selectCurrentProject} from '../../utils';
import {IconButton} from '../Button/IconButton';

const Wrapper = styled.footer`
  border-top: solid 2px ${COLORS.Primary50};
  
  padding: 40px 0 16px 0;
  
  margin-bottom: 16px;
  
  ${BREAKPOINTS.LARGE}{
    margin-bottom: 32px;
  }
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 32px;
  
  padding: 0 16px;
  
  ${BREAKPOINTS.MEDIUM}{
    padding: 0 32px;
  }

  ${BREAKPOINTS.XLARGE}{
    padding: 0 56px;
  }

  
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
    justify-content: space-between;
  }

`;

const Subtitle = styled.div`
  ${ComponentMStyling(TextStyle.SemiBold, COLORS.Neutral900)}
  
  ${BREAKPOINTS.XLARGE}{
    ${ComponentLStyling(TextStyle.SemiBold, COLORS.Neutral900)}
  }
`;

const Paragraph = styled.div`
  ${ParagraphSStyling(TextStyle.Regular, COLORS.Neutral500)}
  max-width: 60ch;
  text-align: center;
  
  span{
    color: ${COLORS.Primary500};
  }
  
  ${BREAKPOINTS.LARGE}{
    text-align: left;
  }

  ${BREAKPOINTS.LARGE}{
    ${ParagraphMStyling(TextStyle.Regular, COLORS.Neutral500)}
  }
`;

const Note = styled.div`
 flex-align: flex-end;

 span{
    color: ${COLORS.Primary500};
  }

  ${ParagraphXSStyling(TextStyle.Regular, COLORS.Neutral500)}
`;

const AboutUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-end;
  
  ${BREAKPOINTS.LARGE}{
    margin-top: auto;
    align-items: flex-start;
  }
`;

const ContactUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
    gap: 80px;
  }
  
  ${BREAKPOINTS.XLARGE}{
    flex-direction: row;
    gap: 96px;
  }
`;

const ContactUsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  align-items: center;

  ${BREAKPOINTS.LARGE}{
    align-items: flex-start;
    gap: 20px;
  }
`;

const ContactItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  ${BREAKPOINTS.LARGE}{
    align-items: flex-start;
  }  
`;

const SocialItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -16px;
  
  ${BREAKPOINTS.LARGE}{
    display: grid;
    grid-template-columns: 50% 50%;
  }
`;


export const Footer: React.FunctionComponent = () => {
  const {t, i18n} = useTranslation();
  const project = useSelector(selectCurrentProject);

  const navigateTo = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Wrapper id={'contact'}>
      <ContentContainer>
        <AboutUsWrapper>
          <Note>
            <Trans ns={'Common'} i18nKey={'ALL_RIGHTS_RESERVED'}>
              ® All rights reserved by <span>Property Invest Group</span> ltd.
            </Trans>
          </Note>
        </AboutUsWrapper>
        <ContactUsWrapper>
          <ContactUsItem>
            <Subtitle>{t('კონტაქტი')}</Subtitle>
            <ContactItems>
              <HyperLink href={`tel:${project?.contact.phone}`}
                         label={project?.contact.phone || ''}
                         icon={<Phone/>}/>
              <HyperLink href={`mailto:${project?.contact.email || ''}`}
                         label={project?.contact.email || ''}
                         icon={<Email/>}/>
              <HyperLink href={project?.location}
                         target={'_blank'}
                         label={project?.address[i18n.language] || ''}
                         icon={<Location/>}/>
            </ContactItems>
          </ContactUsItem>

          <ContactUsItem style={{alignItems: 'center'}}>
            <Subtitle>{t('სოციალური')}</Subtitle>
            <SocialItems>
              {
                !!project?.social.facebook &&
                  <IconButton action={() => navigateTo(project?.social.facebook)} icon={<Facebook/>}/>
              }
              {
                !!project?.social.instagram &&
                  <IconButton action={() => navigateTo(project?.social.instagram)} icon={<Instagram/>}/>
              }
              {
                !!project?.social.whatsapp &&
                  <IconButton action={() => navigateTo(project?.social.whatsapp)} icon={<WhatsApp/>}/>
              }
              {
                !!project?.social.viber &&
                  <IconButton action={() => navigateTo(project?.social.viber)} icon={<Viber/>}/>
              }
            </SocialItems>
          </ContactUsItem>
        </ContactUsWrapper>
      </ContentContainer>
    </Wrapper>
  );
};