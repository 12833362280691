export enum FloorTypes {
  Parking = 'Parking',
  Commercial = 'Commercial',
  Apartments = 'Apartments',
  Penthouse = 'Penthouse',
}

export enum FlatStates {
  Available = 'Available',
  Sold = 'Sold',
  Reserved = 'Reserved',
}

export enum RoomTypes {
  Bedroom = 'Bedroom',
  LivingRoom = 'LivingRoom',
  DiningRoom = 'DiningRoom',
  Kitchen = 'Kitchen',
  BathRoom = 'BathRoom',
  UtilityRoom = 'UtilityRoom',
  GuestRoom = 'GuestRoom',
  Nursery = 'Nursery',
  Study = 'Study',
  Balcony = 'Balcony',
  Hall = 'Hall',
  HomeOffice = 'HomeOffice',
}


export interface Floor {
  floor: string;
  title: LocalizedString;
  type: FloorTypes;

  image: Asset;
  imageRotated: Asset;

  document: string;

  flats: { flat: Flat; container: any }[];
}

export interface Room {
  type: RoomTypes;
  area: number;
}

export interface Flat {
  number: number;
  document: string;
  type: FlatType;
  state: FlatStates;
  area: number;
  height: number;
  rooms: Room[];
  price: {
    price: number;
    loanPrice: number;
  }
}

export interface Project {
  title: LocalizedString;
  flatPrice: {
    price: number;
    loanPrice: number;
  }
  parkingPrice: {
    price: number;
    loanPrice: number;
  }
  vegetation: string;
  slug: { current: string };
  image: Asset;
  address: LocalizedString;
  social: {
    facebook: string;
    instagram: string;
    viber: string;
    whatsapp: string;
  },
  contact: {
    phone: string;
    email: string;
  }
  location: string;
  endDate: Date;
  floors: { floor: Floor; container: string }[];
}

export interface FlatRoom {
  type: RoomTypes;
  location: any;
}

export interface FlatView {
  icon: string;
  image: Asset;
  rooms: { room: FlatRoom }[];
}

export interface FlatType {
  title: LocalizedString;
  views: FlatView[];
}

export interface Asset {
  asset: { _ref: string };
  altText?: string;
}

export interface LocalizedString {
  [language: string]: string;
}

export interface VegetationItem {
  text: LocalizedString;
  note: LocalizedString;
  image: Asset;
}

export interface HomePage {
  apartment: Asset;
  vegetation: VegetationItem[];
  sunlight: Asset;
  facilities: Asset;
  parking: Asset;
}