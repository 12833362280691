import React from 'react';
import styled from '@emotion/styled';
import {COLORS} from '../../utils/styles/colors';

const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

type LoadingIndicatorProps = {
  color?: string;
};

const LoadingIndicator = ({color = COLORS.Primary500}: LoadingIndicatorProps) => {
  return (
    <LoadingWrapper>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" role="img" xmlns="http://www.w3.org/2000/svg">
        <title>Loading</title>
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40ZM24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 8C15.1634 8 8 15.1634 8 24C8 25.1046 7.10457 26 6 26C4.89543 26 4 25.1046 4 24C4 12.9543 12.9543 4 24 4C25.1046 4 26 4.89543 26 6C26 7.10457 25.1046 8 24 8Z"
          fill={color}
        />
      </svg>
    </LoadingWrapper>
  );

};

export default LoadingIndicator;
