import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';
import { BREAKPOINTS } from '../../../utils/styles/breakpoints';
import {
    ComponentMStyling,
    ComponentSStyling,
    ComponentXSStyling,
    HeadlineLStyling,
    HeadlineMStyling,
    HeadlineSStyling, HeadlineXSStyling, HeadlineXXSStyling,
    TextStyle,
} from '../../../utils/styles/typography';
import { COLORS } from '../../../utils/styles/colors';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../../../components/Button/IconButton';
import { Close } from '../../../components/Icons/Icons';
import { Toggle } from '../../../components/Toggle/Toggle';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { Flat, Project } from '../../../utils';
import moment from 'moment';
import { Button } from '../../../components/Button/Button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  overflow: auto;
  
  ${BREAKPOINTS.MEDIUM} {
    gap: 20px;
    padding: 20px;
  }
  
  ${BREAKPOINTS.XLARGE} {
    gap: 24px;
    padding: 24px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${BREAKPOINTS.MEDIUM}{
    gap: 16px;
  }
`;

const HeaderTitle = styled.div`
  ${HeadlineXSStyling(TextStyle.SemiBold, COLORS.Black)}
  
  ${BREAKPOINTS.MEDIUM}{
    ${HeadlineSStyling(TextStyle.SemiBold, COLORS.Black)}
  }

  ${BREAKPOINTS.XLARGE}{
    ${HeadlineMStyling(TextStyle.SemiBold, COLORS.Black)}
  }
`;

const HeaderDescription = styled.p`
  margin: 0;
  padding: 0;
  max-width: 60ch;
  ${ComponentXSStyling(TextStyle.Regular, COLORS.Neutral600)}
  ${BREAKPOINTS.MEDIUM}{
    ${ComponentSStyling(TextStyle.Regular, COLORS.Neutral600)}
  }
  ${BREAKPOINTS.XLARGE}{
    ${ComponentMStyling(TextStyle.Regular, COLORS.Neutral600)}
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CurrencySwitcher = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`;
const Divider = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${COLORS.Neutral100};
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 32px;
  
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${BREAKPOINTS.MEDIUM}{
    gap: 12px;
  }
  ${BREAKPOINTS.XLARGE}{
    gap: 16px;
  }
`;

const InputLabel = styled.label`
  ${ComponentXSStyling(TextStyle.Medium, COLORS.Neutral700)}
  
  ${BREAKPOINTS.MEDIUM}{
    ${ComponentSStyling(TextStyle.Medium, COLORS.Neutral700)}
  }
  ${BREAKPOINTS.XLARGE}{
    ${ComponentMStyling(TextStyle.Medium, COLORS.Neutral700)}
  }
`;


const SummaryWrapper = styled.div`
  background-color: ${COLORS.Primary50};
  border: 1px solid ${COLORS.Primary100};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
  gap: 16px;
  
  ${BREAKPOINTS.MEDIUM}{
    flex-direction: row;
  }
`;

const SummaryDivider = styled.div`
  width: 1px;
  height: 48px;
  background-color: ${COLORS.Primary100};
  display: none;
  
  ${BREAKPOINTS.MEDIUM}{
    display: block;
  }
`;

const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const SummaryItemTitle = styled.div`
  ${ComponentXSStyling(TextStyle.Medium, COLORS.Neutral900)}
  
  ${BREAKPOINTS.MEDIUM}{
    ${ComponentSStyling(TextStyle.Medium, COLORS.Neutral900)}
  }
  ${BREAKPOINTS.XLARGE}{
    ${ComponentMStyling(TextStyle.Medium, COLORS.Neutral900)}
  }
`;

const SummaryItemValue = styled.div`
  display: flex;
  justify-content: center;
  
  ${HeadlineSStyling(TextStyle.Medium, COLORS.Primary900)}
  ${BREAKPOINTS.MEDIUM}{
    ${HeadlineMStyling(TextStyle.Medium, COLORS.Primary900)}
  }
  ${BREAKPOINTS.XLARGE}{
    ${HeadlineLStyling(TextStyle.Medium, COLORS.Primary900)}
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface Props {
    isOpen: boolean;
    onClose: () => void;
    rates: { value: string, label: string, rate: number }[];
    project: Project;
    flat: Flat;
}

export const CalculatorModal = ({ isOpen, onClose, rates, flat, project }: Props) => {
    const { t } = useTranslation();

    const [currency, setCurrency] = useState('USD');
    const [interval, setInterval] = useState<'month' | 'quarter'>('month');
    const [deposit, setDeposit] = useState(0);

    const rate = rates.find(a => a.value === currency)?.rate ?? 1;
    const currencySign = rates.find(a => a.value === currency)?.label ?? '$';

    const area = flat.area ?? 0;
    const pricePerMeter = Math.round((flat.price?.loanPrice ?? project.flatPrice?.loanPrice ?? 0) * rate);
    const price = Math.floor(pricePerMeter * area);

    const paymentCount = moment(project.endDate).startOf(interval).diff(moment().startOf(interval), interval);
    const payment = Math.floor((price - deposit) / paymentCount);

    useEffect(() => {
        setDeposit(Math.floor(price * 0.3));
    }, [flat]);

    return (
        <ReactModal isOpen={isOpen}
            style={{
                overlay: {
                    background: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
                content: {
                    borderRadius: '8px',
                    padding: '0',
                    inset: 'unset',
                    display: 'flex',
                    maxWidth: 'calc(100% - 64px)',
                    maxHeight: 'calc(100% - 64px)',
                    height: 'fit-content',
                    width: 'fit-content',
                    flexDirection: 'column',
                    border: `1px solid ${COLORS.Neutral200}`,
                    filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.15)) drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15))',
                },
            }}
            onRequestClose={onClose}>
            <Wrapper>
                <Header>
                    <HeaderText>
                        <HeaderTitle>{t('კალკულატორი')}</HeaderTitle>
                        <HeaderDescription>{t('განვადების პირობები')}</HeaderDescription>
                    </HeaderText>
                    <IconButton action={onClose} icon={<Close />} />
                </Header>
                <Content>
                    <CurrencySwitcher>
                        <Divider />
                        <Toggle value={currency}
                            onChange={setCurrency}
                            items={rates} />
                    </CurrencySwitcher>

                    <FormWrapper>
                        <InputGroup>
                            <InputLabel>{t('გადახდის ინტერვალი')}</InputLabel>
                            <RadioGroup row={true}
                                value={interval}
                                onChange={(event, value) => setInterval(value as any)}>
                                <FormControlLabel value="month" control={<Radio />} label={t('თვიური')} />
                                <FormControlLabel value="quarter" control={<Radio />} label={t('კვარტლური')} />
                            </RadioGroup>
                        </InputGroup>
                        <InputGroup>
                            <InputLabel>
                                {t('პირველადი შენატანი, მინიმუმ 30%')}
                            </InputLabel>
                            <TextField
                                disabled={true}
                                variant={'outlined'}
                                inputProps={{ pattern: '[0-9]*' }}
                                InputProps={{ inputMode: 'numeric', startAdornment: (currencySign) }}
                                value={Math.round((deposit * rate)).toLocaleString('en')}
                                onChange={e => setDeposit(+e.target.value)} />
                        </InputGroup>
                    </FormWrapper>

                    <SummaryWrapper>

                        <SummaryItem>
                            <SummaryItemTitle>
                                {t('ფასი მ²')}
                            </SummaryItemTitle>
                            <SummaryItemValue style={{ minWidth: '6ch' }}>
                                {currencySign} {(pricePerMeter).toLocaleString('en')}
                            </SummaryItemValue>
                        </SummaryItem>

                        <SummaryDivider />

                        <SummaryItem>
                            <SummaryItemTitle>
                                {t('ბინის ფასი')}
                            </SummaryItemTitle>
                            <SummaryItemValue style={{ minWidth: '10ch' }}>
                                {currencySign} {(price).toLocaleString('en')}
                            </SummaryItemValue>
                        </SummaryItem>

                        <SummaryDivider />

                        <SummaryItem>
                            <SummaryItemTitle>
                                {t('გადასახდელი თანხა')}
                            </SummaryItemTitle>
                            <SummaryItemValue style={{ minWidth: '6ch' }}>
                                {currencySign} {(payment).toLocaleString('en')}
                            </SummaryItemValue>
                        </SummaryItem>
                        <SummaryDivider />
                        <SummaryItem>
                            <SummaryItemTitle>
                                {t('გადახდების რაოდენობა')}
                            </SummaryItemTitle>
                            <SummaryItemValue style={{ minWidth: '6ch' }}>
                                {(paymentCount).toLocaleString('en')}
                            </SummaryItemValue>
                        </SummaryItem>

                    </SummaryWrapper>


                </Content>
                <Footer>
                    <Button action={onClose} style={{ flex: 0 }} label={t('დახურვა')} variant={'filled'} />
                </Footer>
            </Wrapper>
        </ReactModal>
    );

};