import {css} from '@emotion/react';
import {COLORS} from "./styles/colors";

const GlobalStyles = css`

  @font-face {
    font-family: 'Inter Numeric';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq14DyQXcDrOAgjkPkKFrz&skey=c491285d6722e4fa&v=v12) format('woff2');
  }
  @font-face {
    font-family: 'Inter Numeric';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq14DyQXcDrOAgjkPkKFrz&skey=c491285d6722e4fa&v=v12) format('woff2');
  }
  @font-face {
    font-family: 'Inter Numeric';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq14DyQXcDrOAgjkPkKFrz&skey=c491285d6722e4fa&v=v12) format('woff2');
  }

  html, body {
    margin: 0;
    padding: 0;
  }
  
  body.dark{
    background-color: ${COLORS.Neutral100};
  }
`;

export default GlobalStyles;