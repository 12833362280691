import React, {createRef, useEffect, useRef} from 'react';
import styled from '@emotion/styled';
import {matchPath, useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useDimensionsRef, useWindowSize} from 'rooks';
import {ComponentMStyling, ComponentSStyling, TextStyle} from '../../../utils/styles/typography';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import {COLORS} from '../../../utils/styles/colors';
import {shouldHandleButtonPress} from '../../../utils/helpers';
import {selectCurrentProject, selectProjects} from '../../../utils';
import {useSelector} from 'react-redux';
import {current} from '@reduxjs/toolkit';

const NavigationItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
  padding: 14px 16px 14px 16px;
  ${ComponentSStyling(TextStyle.Medium, COLORS.Neutral600)};

  ${BREAKPOINTS.LARGE}{
    ${ComponentMStyling(TextStyle.Medium, COLORS.Neutral600)};
    padding: 12px 20px 12px 20px;
  }
  
  &:hover {
    color: ${COLORS.Primary700};
  }
  
  &:active{
    background-color: ${COLORS.Neutral50};
    color: ${COLORS.Primary900};
  }
  
  &.active{
    color: ${COLORS.Primary700};
  }
`;

const Container = styled.nav`
  display: flex;
  flex-direction: column;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActiveBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(48px + 8px + 8px)
`;

const ActiveBar = styled.div`
  position: absolute;
  bottom: 10px;
  height: 2px;
  background-color: ${COLORS.Primary700};
  border-radius: 1px;
  width: 40px;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
`;


export const NavigationMenu = () => {
  const {t, i18n} = useTranslation();

  const barRef = createRef<HTMLDivElement>();
  const navigate = useNavigate();
  const location = useLocation();

  const {innerWidth} = useWindowSize();
  const [hoveredItem, setHoveredItem] = React.useState<HTMLDivElement>();

  const [ref, dimensions, node] = useDimensionsRef();

  const projects = useSelector(selectProjects);

  const isPathActive = (path: string) => {
    const match = matchPath(path, location.pathname);
    return match !== null;
  };

  const handleNavigationClick = (path: string) => {
    navigate(path);
  };

  const handleMouseEnter = (event: React.MouseEvent) => {
    setHoveredItem(event.currentTarget as HTMLDivElement);
  };
  const handleMouseLeave = (event: React.MouseEvent) => {
    setHoveredItem(undefined);
  };

  useEffect(() => {
    if (barRef.current) {
      const item = hoveredItem ?? document.querySelector('.active.nav') as HTMLDivElement;
      const bar = barRef.current;
      const rect = item.getBoundingClientRect();
      bar.style.left = `${rect.left + (rect.right - rect.left) / 2 - 20}px`;
    }
  }, [hoveredItem, location, innerWidth, i18n.language, dimensions?.width, dimensions?.height]);

  return (
    <Container ref={ref}>
      <ItemsContainer>
        <NavigationItem tabIndex={0}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className={isPathActive('/') ? 'active nav' : ''}
                        onClick={() => handleNavigationClick('/')}
                        onKeyDown={(e) => shouldHandleButtonPress(e) && handleNavigationClick('/')}>
          {t('Navigation.Home')}
        </NavigationItem>
        <NavigationItem tabIndex={0}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className={isPathActive('/project/*') ? 'active nav' : ''}
                        onClick={() => handleNavigationClick(`/project/${projects[0]?.slug.current}`)}
                        onKeyDown={(e) => shouldHandleButtonPress(e) && handleNavigationClick(`/project/${projects[0]?.slug.current}`)}>
          {t('Navigation.Apartments')}
        </NavigationItem>
      </ItemsContainer>
      <ActiveBarContainer>
        <ActiveBar ref={barRef}/>
      </ActiveBarContainer>

    </Container>
  );
};