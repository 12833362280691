import React from 'react';
import styled from '@emotion/styled/macro';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectCurrentProject, selectHomePageData} from '../../../utils';
import {HeaderText, ParagraphText} from '../styles';
import {
  ComponentLStyling,
  ComponentMStyling,
  ComponentSStyling,
  ComponentXXSStyling,
  TextStyle,
} from '../../../utils/styles/typography';
import {COLORS} from '../../../utils/styles/colors';
import {HyperLink} from '../../../components/HyperLink/HyperLink';
import {PDF} from '../../../components/Icons/Icons';
import sanity from '../../../utils/sanity';
import {Swiper, SwiperSlide} from 'swiper/react';
import {A11y, Autoplay, Navigation, Pagination, Scrollbar} from 'swiper';

const Background = styled.div`
  background-color: ${COLORS.Primary50};
  padding: 16px 16px;
  ${BREAKPOINTS.MEDIUM}{
    padding: 16px 32px;
  }

  ${BREAKPOINTS.MEDIUM}{
    padding: 28px 32px;
  }

  ${BREAKPOINTS.XLARGE}{
    padding: 56px 56px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  ${BREAKPOINTS.LARGE}{
    max-width: 274px;
    align-items: flex-start;
    gap: 56px;
  }
`;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  ${BREAKPOINTS.LARGE}{
    align-items: flex-start;
  }
  
  span{
    ${ComponentXXSStyling(TextStyle.Medium, COLORS.Neutral900)}
  }
`;

const DocumentText = styled.div`
  ${ComponentXXSStyling(TextStyle.Medium, COLORS.Neutral900)}
`;

const ImagesContainer = styled.div`
    max-width: 208px;
    
    ${BREAKPOINTS.MEDIUM}{
      max-width: 724px;
    }
`;

const ImageText = styled.div`
  ${ComponentMStyling(TextStyle.Medium, COLORS.MoodOne500)}
  ${BREAKPOINTS.LARGE}{
    ${ComponentLStyling(TextStyle.Medium, COLORS.MoodOne500)}
  }
`;

const ImageNote = styled.div`
  ${ComponentSStyling(TextStyle.Regular, COLORS.MoodOne300)}
  ${BREAKPOINTS.LARGE}{
    ${ComponentMStyling(TextStyle.Regular, COLORS.MoodOne300)}
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  gap: 12px;
  min-width: 204px;
  box-sizing: border-box;
  border-radius: 16px;
  
  img{
    
    height: 200px;
    
    ${BREAKPOINTS.MEDIUM}{
      height: 280px;
    }
    ${BREAKPOINTS.LARGE}{
      height: 380px;
    }

  }
`;

export const VegetationSection = () => {
  const {t, i18n} = useTranslation();
  const data = useSelector(selectHomePageData);
  const project = useSelector(selectCurrentProject);

  return <Background>

    <Wrapper>
      <ContentContainer>
        <HeaderText>
          <Trans ns={'Common'} i18nKey={'HomePageVegetationHeader'}>
            სუფთა <i>ჰაერი</i> სახლში
          </Trans>
        </HeaderText>
        <ParagraphText>
          <Trans ns={'Common'} i18nKey={'HomePageApartmentVegetation'}>
            განხორციელდება 2318 მ² ტერიტორიის გამწვანება.
            ტერიტორიაზე უკვე დარგულია 95 ხე-მცენარე. დამატებით დაირგვება 86 ხე-მცენარე.
          </Trans>
        </ParagraphText>

        <DocumentContainer>
          <DocumentText>
            {t('გაინტერესებს გამწვანების გეგმა?')}
          </DocumentText>
          <HyperLink label={t('გადმოწერე')} icon={<PDF/>} href={project!.vegetation}/>
        </DocumentContainer>
      </ContentContainer>
      <ImagesContainer>
        <Swiper spaceBetween={56}
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                slidesPerView={1}
                breakpoints={{
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 32,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 56,
                  },
                }}
                loop={true}
                pagination={{
                  dynamicBullets: true,
                }}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                }}>
          {data?.vegetation.map((image, index) =>
            <SwiperSlide key={index}>
              <ImageContainer>
                <img alt={image.image.altText}
                     src={sanity.imageUrlBuilder(image.image.asset._ref).format('png').url()}/>
                <ImageText>{image.text[i18n.language]}</ImageText>
                <ImageNote>{image.note[i18n.language]}</ImageNote>
              </ImageContainer>
            </SwiperSlide>,
          )}
        </Swiper>

      </ImagesContainer>

    </Wrapper>
  </Background>;
};