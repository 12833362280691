import React from 'react';
import {RoomTypes} from '../../utils';

export const BathRoom = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill={'currentColor'}>
      <path
        d="M216 154.667H39.9999C38.5266 154.667 37.3333 153.473 37.3333 152C37.3333 150.527 38.5266 149.333 39.9999 149.333H216C217.473 149.333 218.667 150.527 218.667 152C218.667 153.473 217.473 154.667 216 154.667Z"
      />
      <path
        d="M189.333 26.6667H66.6666C65.1933 26.6667 63.9999 25.4733 63.9999 24C63.9999 22.5267 65.1933 21.3333 66.6666 21.3333H189.333C190.807 21.3333 192 22.5267 192 24C192 25.4733 190.807 26.6667 189.333 26.6667Z"
      />
      <path
        d="M182.473 32C184.787 32 186.667 33.9467 186.667 36.3467V105.88C186.667 108.28 182.913 110.227 178.287 110.227C173.653 110.227 169.907 108.28 169.907 105.88C169.907 103.48 166.153 101.533 161.527 101.533C156.893 101.533 153.147 103.48 153.147 105.88C153.147 108.28 149.393 110.227 144.767 110.227C140.133 110.227 136.387 108.28 136.387 105.88C136.387 103.48 132.633 101.533 128.007 101.533C123.38 101.533 119.627 103.48 119.627 105.88C119.627 108.28 115.873 110.227 111.247 110.227C106.613 110.227 102.867 108.28 102.867 105.88C102.867 103.48 99.1133 101.533 94.4866 101.533C89.8532 101.533 86.1066 103.48 86.1066 105.88C86.1066 108.28 82.3533 110.227 77.7266 110.227C73.0933 110.227 69.3466 108.28 69.3466 105.88V36.3467C69.3466 33.9467 71.2199 32 73.5399 32H182.473Z"
      />
      <path
        d="M213.333 162.667V197.333C213.333 209.113 203.78 218.667 192 218.667H63.9999C52.2199 218.667 42.6666 209.113 42.6666 197.333V162.667C42.6666 161.193 43.8599 160 45.3333 160H210.667C212.14 160 213.333 161.193 213.333 162.667Z"
      />
      <path d="M87.9999 218.667H82.6666V229.333H87.9999V218.667Z"/>
      <path d="M173.333 218.667H168V229.333H173.333V218.667Z"/>
      <path
        d="M90.6666 234.667H79.9999C78.5266 234.667 77.3333 233.473 77.3333 232C77.3333 230.527 78.5266 229.333 79.9999 229.333H90.6666C92.1399 229.333 93.3333 230.527 93.3333 232C93.3333 233.473 92.1399 234.667 90.6666 234.667Z"
      />
      <path
        d="M176 234.667H165.333C163.86 234.667 162.667 233.473 162.667 232C162.667 230.527 163.86 229.333 165.333 229.333H176C177.473 229.333 178.667 230.527 178.667 232C178.667 233.473 177.473 234.667 176 234.667Z"
      />
    </svg>
  );
};

export const BedRoom = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill={'currentColor'}>
      <path
        d="M221.333 218.667H34.6667C33.1933 218.667 32 217.473 32 216V200C32 198.527 33.1933 197.333 34.6667 197.333H221.333C222.807 197.333 224 198.527 224 200V216C224 217.473 222.807 218.667 221.333 218.667Z"
      />
      <path
        d="M221.333 192H34.6667C33.1933 192 32 190.807 32 189.333V170.667C32 164.773 36.7733 160 42.6667 160H213.333C219.227 160 224 164.773 224 170.667V189.333C224 190.807 222.807 192 221.333 192Z"
      />
      <path
        d="M202.667 117.333V154.667H181.333V140.667C181.333 136.613 178.053 133.333 174 133.333H146.667C142.247 133.333 138.667 136.913 138.667 141.333V154.667H117.333V140.667C117.333 136.613 114.053 133.333 110 133.333H82.6667C78.2467 133.333 74.6667 136.913 74.6667 141.333V154.667H53.3333V117.333C53.3333 111.44 58.1067 106.667 64 106.667H192C197.893 106.667 202.667 111.44 202.667 117.333Z"
      />
      <path
        d="M57.2533 245.333H51.3333C49.86 245.333 48.6667 244.14 48.6667 242.667V226.667C48.6667 225.193 49.86 224 51.3333 224H61.2533C62.9867 224 64.26 225.633 63.84 227.313L59.84 243.313C59.54 244.5 58.4733 245.333 57.2533 245.333Z"
      />
      <path
        d="M198.747 245.333H204.667C206.14 245.333 207.333 244.14 207.333 242.667V226.667C207.333 225.193 206.14 224 204.667 224H194.747C193.013 224 191.74 225.633 192.16 227.313L196.16 243.313C196.46 244.5 197.527 245.333 198.747 245.333Z"
      />
      <path
        d="M152 43.5C148.187 52.4134 139.32 58.6667 129.007 58.6667C115.193 58.6667 104 47.4734 104 33.66C104 23.34 110.253 14.48 119.167 10.6667C117.88 13.6867 117.16 17.0067 117.16 20.5067C117.16 34.32 128.353 45.5134 142.167 45.5134C145.66 45.5067 148.98 44.7867 152 43.5Z"
      />
    </svg>
  );
};

export const LivingRoom = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill={'currentColor'}>
      <path
        d="M201.333 213.333H54.6665C53.9332 213.333 53.3332 213.933 53.3332 214.667V233.333C53.3332 234.067 53.9332 234.667 54.6665 234.667H201.333C202.066 234.667 202.666 234.067 202.666 233.333V214.667C202.666 213.933 202.066 213.333 201.333 213.333Z"
      />
      <path
        d="M209.333 234.667H225.333C227.54 234.667 229.333 232.873 229.333 230.667V196C229.333 193.793 227.54 192 225.333 192H212C209.793 192 208 193.793 208 196V233.333C208 234.067 208.6 234.667 209.333 234.667Z"
      />
      <path
        d="M46.6665 234.667H30.6665C28.4598 234.667 26.6665 232.873 26.6665 230.667V196C26.6665 193.793 28.4598 192 30.6665 192H43.9998C46.2065 192 47.9998 193.793 47.9998 196V233.333C47.9998 234.067 47.3998 234.667 46.6665 234.667Z"
      />
      <path
        d="M213.333 164V185.333C213.333 186.067 212.733 186.667 212 186.667H210.667C206.247 186.667 202.667 190.247 202.667 194.667V206C202.667 207.107 201.773 208 200.667 208H164C161.793 208 160 206.207 160 204V164C160 161.793 161.793 160 164 160H209.333C211.54 160 213.333 161.793 213.333 164Z"
      />
      <path
        d="M150.667 208H105.333C103.127 208 101.333 206.207 101.333 204V164C101.333 161.793 103.127 160 105.333 160H150.667C152.873 160 154.667 161.793 154.667 164V204C154.667 206.207 152.873 208 150.667 208Z"
      />
      <path
        d="M95.9998 164V204C95.9998 206.207 94.2065 208 91.9998 208H54.6665C53.9332 208 53.3332 207.4 53.3332 206.667V194C53.3332 189.947 50.0532 186.667 45.9998 186.667H43.9998C43.2665 186.667 42.6665 186.067 42.6665 185.333V164C42.6665 161.793 44.4598 160 46.6665 160H91.9998C94.2065 160 95.9998 161.793 95.9998 164Z"
      />
      <path
        d="M189.333 21.3334H66.6665C65.1932 21.3334 63.9998 22.5267 63.9998 24V93.3334C63.9998 94.8067 65.1932 96 66.6665 96H189.333C190.807 96 192 94.8067 192 93.3334V24C192 22.5267 190.807 21.3334 189.333 21.3334ZM186.667 90.6667H69.3332V26.6667H186.667V90.6667Z"
      />
      <path
        d="M168 53.3334C172.418 53.3334 176 49.7517 176 45.3334C176 40.9151 172.418 37.3334 168 37.3334C163.582 37.3334 160 40.9151 160 45.3334C160 49.7517 163.582 53.3334 168 53.3334Z"
      />
      <path d="M74.6665 85.3334L95.9998 45.3334L117.333 85.3334H74.6665Z"/>
      <path d="M117.333 85.3334L130.667 64L144 85.3334H117.333Z"/>
    </svg>
  );
};

export const Hall = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill={'currentColor'}>
      <path
        d="M205.333 170.667H50.6667C46.2467 170.667 42.6667 174.247 42.6667 178.667V216C42.6667 220.42 46.2467 224 50.6667 224H205.333C209.753 224 213.333 220.42 213.333 216V178.667C213.333 174.247 209.753 170.667 205.333 170.667ZM64.0001 202.667C61.0534 202.667 58.6667 200.28 58.6667 197.333C58.6667 194.387 61.0534 192 64.0001 192C66.9467 192 69.3334 194.387 69.3334 197.333C69.3334 200.28 66.9467 202.667 64.0001 202.667ZM165.333 216C165.333 217.473 164.14 218.667 162.667 218.667H93.3334C91.8601 218.667 90.6667 217.473 90.6667 216V178.667C90.6667 177.193 91.8601 176 93.3334 176H162.667C164.14 176 165.333 177.193 165.333 178.667V216ZM192 202.667C189.053 202.667 186.667 200.28 186.667 197.333C186.667 194.387 189.053 192 192 192C194.947 192 197.333 194.387 197.333 197.333C197.333 200.28 194.947 202.667 192 202.667Z"
      />
      <path
        d="M88.0001 245.333H82.6667C81.1934 245.333 80.0001 244.14 80.0001 242.667V232C80.0001 230.527 81.1934 229.333 82.6667 229.333H88.0001C89.4734 229.333 90.6667 230.527 90.6667 232V242.667C90.6667 244.14 89.4734 245.333 88.0001 245.333Z"
      />
      <path
        d="M173.333 245.333H168C166.527 245.333 165.333 244.14 165.333 242.667V232C165.333 230.527 166.527 229.333 168 229.333H173.333C174.807 229.333 176 230.527 176 232V242.667C176 244.14 174.807 245.333 173.333 245.333Z"
      />
      <path
        d="M125.333 205.333H112V197.333C112 194.387 114.387 192 117.333 192H120C122.947 192 125.333 194.387 125.333 197.333V205.333Z"
      />
      <path d="M125.333 208H112V213.333H125.333V208Z"/>
      <path
        d="M144 205.333H130.667V197.333C130.667 194.387 133.053 192 136 192H138.667C141.613 192 144 194.387 144 197.333V205.333Z"
      />
      <path d="M144 208H130.667V213.333H144V208Z"/>
      <path
        d="M128 13.3334C147.113 13.3334 162.667 28.8867 162.667 48V104C162.667 123.113 147.113 138.667 128 138.667C108.887 138.667 93.3334 123.113 93.3334 104V48C93.3334 28.8867 108.887 13.3334 128 13.3334ZM128 10.6667C107.38 10.6667 90.6667 27.38 90.6667 48V104C90.6667 124.62 107.38 141.333 128 141.333C148.62 141.333 165.333 124.62 165.333 104V48C165.333 27.38 148.62 10.6667 128 10.6667Z"
      />
      <path
        d="M157.293 39.44L97.0601 99.6734V51.0934C97.0601 33.0534 111 18.4267 128.193 18.4267C141.487 18.4267 152.827 27.1534 157.293 39.44Z"
      />
      <path
        d="M159.333 51.76V55.4867L100.06 114.76C99.2001 112.86 98.5067 110.853 98.0067 108.767L159.093 47.68C159.253 49.02 159.333 50.38 159.333 51.76Z"
      />
      <path
        d="M107.973 125.6L159.333 74.24V100.76C159.333 118.8 145.393 133.427 128.2 133.427C120.48 133.427 113.413 130.473 107.973 125.6Z"
      />
    </svg>
  );
};

export const Balcony = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill={'currentColor'}>
      <path
        d="M176 21.3334H79.9998C75.5998 21.3334 71.9998 24.9334 71.9998 29.3334V154.667H82.6665V34.6667C82.6665 33.2 83.8665 32 85.3332 32H170.667C172.133 32 173.333 33.2 173.333 34.6667V154.667H184V29.3334C184 24.9334 180.4 21.3334 176 21.3334Z"/>
      <path
        d="M225.333 234.667H30.6665C28.4665 234.667 26.6665 232.867 26.6665 230.667V217.333C26.6665 215.133 28.4665 213.333 30.6665 213.333H225.333C227.533 213.333 229.333 215.133 229.333 217.333V230.667C229.333 232.867 227.533 234.667 225.333 234.667Z"/>
      <path
        d="M54.6665 149.333H41.3332C39.1332 149.333 37.3332 151.133 37.3332 153.333V208H58.6665V153.333C58.6665 151.133 56.8665 149.333 54.6665 149.333Z"/>
      <path
        d="M214.667 149.333H201.333C199.133 149.333 197.333 151.133 197.333 153.333V208H218.667V153.333C218.667 151.133 216.867 149.333 214.667 149.333Z"/>
      <path d="M130.667 32H125.333V144H130.667V32Z"/>
      <path
        d="M138.667 106.667H128C126.533 106.667 125.333 105.467 125.333 104C125.333 102.533 126.533 101.333 128 101.333H138.667C140.133 101.333 141.333 102.533 141.333 104C141.333 105.467 140.133 106.667 138.667 106.667Z"/>
      <path d="M197.333 160H58.6665V165.333H197.333V160Z"/>
      <path
        d="M134.667 149.333H121.333C119.133 149.333 117.333 151.133 117.333 153.333V208H138.667V153.333C138.667 151.133 136.867 149.333 134.667 149.333Z"/>
    </svg>
  );
};

export const Kitchen = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill={'currentColor'}>
      <path d="M144 26.6667H112V48H144V26.6667Z"/>
      <path
        d="M218.667 154.667H37.3333C34.4 154.667 32 157.067 32 160V213.333C32 216.267 34.4 218.667 37.3333 218.667H218.667C221.6 218.667 224 216.267 224 213.333V160C224 157.067 221.6 154.667 218.667 154.667ZM58.6667 208C52.8 208 48 203.2 48 197.333V176C48 170.133 52.8 165.333 58.6667 165.333H59.8667L78.8 208H58.6667ZM89.4667 208L70.5333 165.333H75.8667L94.8 208H89.4667ZM122.667 197.333C122.667 203.2 117.867 208 112 208H100.133L81.2 165.333H112C117.867 165.333 122.667 170.133 122.667 176V197.333ZM188.533 204.533C188.067 205 187.4 205.333 186.667 205.333H176C174.533 205.333 173.333 204.133 173.333 202.667C173.333 201.2 174.533 200 176 200H186.667C188.133 200 189.333 201.2 189.333 202.667C189.333 203.4 189 204.067 188.533 204.533ZM188.533 188.533C188.067 189 187.4 189.333 186.667 189.333H176C174.533 189.333 173.333 188.133 173.333 186.667C173.333 185.2 174.533 184 176 184H186.667C188.133 184 189.333 185.2 189.333 186.667C189.333 187.4 189 188.067 188.533 188.533ZM188.533 172.533C188.067 173 187.4 173.333 186.667 173.333H176C174.533 173.333 173.333 172.133 173.333 170.667C173.333 169.2 174.533 168 176 168H186.667C188.133 168 189.333 169.2 189.333 170.667C189.333 171.4 189 172.067 188.533 172.533Z"/>
      <path
        d="M178.667 80H77.9333C75.4 80 74.2667 76.7333 76.3333 75.2L112 48H144L180.267 75.2C182.333 76.7333 181.2 80 178.667 80Z"/>
      <path
        d="M125.333 138.667H45.3333C43.8667 138.667 42.6667 139.867 42.6667 141.333V146.667C42.6667 148.133 43.8667 149.333 45.3333 149.333H125.333C126.8 149.333 128 148.133 128 146.667V141.333C128 139.867 126.8 138.667 125.333 138.667ZM56 146.667C54.5333 146.667 53.3333 145.467 53.3333 144C53.3333 142.533 54.5333 141.333 56 141.333C57.4667 141.333 58.6667 142.533 58.6667 144C58.6667 145.467 57.4667 146.667 56 146.667ZM66.6667 146.667C65.2 146.667 64 145.467 64 144C64 142.533 65.2 141.333 66.6667 141.333C68.1333 141.333 69.3333 142.533 69.3333 144C69.3333 145.467 68.1333 146.667 66.6667 146.667ZM77.3333 146.667C75.8667 146.667 74.6667 145.467 74.6667 144C74.6667 142.533 75.8667 141.333 77.3333 141.333C78.8 141.333 80 142.533 80 144C80 145.467 78.8 146.667 77.3333 146.667ZM88 146.667C86.5333 146.667 85.3333 145.467 85.3333 144C85.3333 142.533 86.5333 141.333 88 141.333C89.4667 141.333 90.6667 142.533 90.6667 144C90.6667 145.467 89.4667 146.667 88 146.667ZM114.667 146.667C113.2 146.667 112 145.467 112 144C112 142.533 113.2 141.333 114.667 141.333C116.133 141.333 117.333 142.533 117.333 144C117.333 145.467 116.133 146.667 114.667 146.667Z"/>
      <path
        d="M178.667 90.6667H77.3333C75.8667 90.6667 74.6667 89.4667 74.6667 88C74.6667 86.5333 75.8667 85.3333 77.3333 85.3333H178.667C180.133 85.3333 181.333 86.5333 181.333 88C181.333 89.4667 180.133 90.6667 178.667 90.6667Z"/>
      <path
        d="M148 26.6667H108C107.267 26.6667 106.667 26.0667 106.667 25.3333V17.3333C106.667 16.6 107.267 16 108 16H148C148.733 16 149.333 16.6 149.333 17.3333V25.3333C149.333 26.0667 148.733 26.6667 148 26.6667Z"/>
      <path
        d="M50.6667 224H56C57.4667 224 58.6667 225.2 58.6667 226.667V237.333C58.6667 238.8 57.4667 240 56 240H50.6667C49.2 240 48 238.8 48 237.333V226.667C48 225.2 49.2 224 50.6667 224Z"/>
      <path
        d="M200 224H205.333C206.8 224 208 225.2 208 226.667V237.333C208 238.8 206.8 240 205.333 240H200C198.533 240 197.333 238.8 197.333 237.333V226.667C197.333 225.2 198.533 224 200 224Z"/>
    </svg>
  );
};

export const Office = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill={'currentColor'}>
      <path
        d="M210.667 165.333H45.3332C43.8665 165.333 42.6665 164.133 42.6665 162.667V152C42.6665 150.533 43.8665 149.333 45.3332 149.333H210.667C212.133 149.333 213.333 150.533 213.333 152V162.667C213.333 164.133 212.133 165.333 210.667 165.333Z"/>
      <path
        d="M146.666 61.3333V112H55.9998V61.3333C55.9998 56.9333 59.5998 53.3333 63.9998 53.3333H138.666C143.066 53.3333 146.666 56.9333 146.666 61.3333Z"/>
      <path
        d="M55.9998 120C55.9998 124.4 59.5998 128 63.9998 128H98.6665V138.667H87.9998C87.2665 138.667 86.5998 139 86.1332 139.467C85.6665 139.933 85.3998 140.533 85.3332 141.2C85.2665 142.733 86.5998 144 88.1332 144H114.666C115.4 144 116.066 143.667 116.533 143.2C117 142.733 117.267 142.133 117.333 141.467C117.4 139.933 116.066 138.667 114.533 138.667H104V128H138.666C143.066 128 146.666 124.4 146.666 120V114.667H55.9998V120ZM93.7332 120.4C93.9998 120.133 94.3332 120 94.6665 120H108C108.733 120 109.333 120.6 109.333 121.333C109.333 121.667 109.2 122 108.933 122.267C108.666 122.533 108.333 122.667 108 122.667H94.6665C93.9332 122.667 93.3332 122.067 93.3332 121.333C93.3332 121 93.4665 120.667 93.7332 120.4Z"/>
      <path
        d="M192 48H170.667C166.267 48 162.667 51.6 162.667 56V136C162.667 140.4 166.267 144 170.667 144H192C196.4 144 200 140.4 200 136V56C200 51.6 196.4 48 192 48ZM181.333 130.667C179.867 130.667 178.667 129.467 178.667 128C178.667 126.533 179.867 125.333 181.333 125.333C182.8 125.333 184 126.533 184 128C184 129.467 182.8 130.667 181.333 130.667ZM191.2 87.2C190.733 87.6667 190.067 88 189.333 88H173.333C171.867 88 170.667 86.8 170.667 85.3333C170.667 84.6 171 83.9333 171.467 83.4667C171.933 83 172.6 82.6667 173.333 82.6667H189.333C190.8 82.6667 192 83.8667 192 85.3333C192 86.0667 191.666 86.7333 191.2 87.2ZM191.2 76.5333C190.733 77 190.067 77.3333 189.333 77.3333H173.333C171.867 77.3333 170.667 76.1333 170.667 74.6667C170.667 73.9333 171 73.2667 171.467 72.8C171.933 72.3333 172.6 72 173.333 72H189.333C190.8 72 192 73.2 192 74.6667C192 75.4 191.666 76.0667 191.2 76.5333ZM191.2 65.8667C190.733 66.3333 190.067 66.6667 189.333 66.6667H173.333C171.867 66.6667 170.667 65.4667 170.667 64C170.667 63.2667 171 62.6 171.467 62.1333C171.933 61.6667 172.6 61.3333 173.333 61.3333H189.333C190.8 61.3333 192 62.5333 192 64C192 64.7333 191.666 65.4 191.2 65.8667Z"/>
      <path
        d="M91.1332 170.667H76.7332C75.5332 170.667 74.4665 171.467 74.1332 172.667L58.8665 231.333C58.3998 233 59.6665 234.667 61.4665 234.667H61.5332C62.5998 234.667 63.5998 234 63.9998 233L93.5332 174.467C94.3998 172.667 93.0665 170.667 91.1332 170.667Z"/>
      <path
        d="M165.533 170.667H179.267C180.467 170.667 181.533 171.467 181.867 172.667L197.133 231.333C197.6 233 196.333 234.667 194.533 234.667H194.467C193.4 234.667 192.4 234 192 233L163.133 174.467C162.267 172.667 163.6 170.667 165.533 170.667Z"/>
    </svg>
  );
};


export const RoomIcons = {
  [RoomTypes.Bedroom]: <BedRoom/>,
  [RoomTypes.LivingRoom]: <LivingRoom/>,
  [RoomTypes.Hall]: <Hall/>,
  [RoomTypes.BathRoom]: <BathRoom/>,
  [RoomTypes.HomeOffice]: <Office/>,
  [RoomTypes.Kitchen]: <Kitchen/>,
  [RoomTypes.DiningRoom]: <Kitchen/>,
  [RoomTypes.GuestRoom]: <BedRoom/>,
  [RoomTypes.Nursery]: <BedRoom/>,

  [RoomTypes.UtilityRoom]: <BathRoom/>,
  [RoomTypes.Study]: <Office/>,
  [RoomTypes.Balcony]: <Balcony/>,
};
