import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from '@emotion/styled';
import {COLORS} from '../../../utils/styles/colors';
import {ComponentSStyling, TextStyle} from '../../../utils/styles/typography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
`;

const LanguageOption = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  padding: 8px 20px;
  box-sizing: border-box;
  width: 100%;
  
  ${ComponentSStyling(TextStyle.SemiBold, COLORS.Neutral500)};
  
  &:hover{
   color: ${COLORS.Neutral700};
  }
  
  &:active{
    color: ${COLORS.Neutral900};
  }
  
  &.active{
    color: ${COLORS.Primary900};
  }

`;


export const LanguageSwitcher = () => {
  const {i18n, t} = useTranslation();

  const languages = ['ka', 'en'];

  return (
    <Container>
      {
        languages.map(lang =>
          <LanguageOption key={lang}
                          lang={lang}
                          className={i18n.language === lang ? 'active' : ''}
                          onClick={(e) => i18n.changeLanguage(lang)}>
            {t(`Language.${lang}`)}
          </LanguageOption>,
        )
      }
    </Container>


  );
};