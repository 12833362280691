import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {selectProjects, setCurrentFlat, setCurrentFloor, setCurrentProject, useProjectsDispatch} from '../../utils';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';


export const useContentLoader = () => {

  const navigate = useNavigate();
  const dispatch = useProjectsDispatch();

  const {project, floor, flat} = useParams<{ project?: string, floor?: string, flat?: string }>();

  const projects = useSelector(selectProjects);

  useEffect(() => {
    if (!projects) {
      return navigate('/');
    }

    const p = projects.find(a => a.slug.current == project);
    if (!p) {
      return navigate('/');
    } else {
      dispatch(setCurrentProject(p!));
    }

    if (!floor) {
      return;
    }

    const f = p.floors.find(a => a.floor.floor == floor);
    if (!f) {
      return navigate(`/project/${project}`);
    } else {
      dispatch(setCurrentFloor(f!.floor!));
    }

    if (!flat) {
      return;
    }

    const fl = f?.floor.flats.find(a => a.flat.number == +flat);
    if (!fl) {
      return navigate(`/project/${project}/${floor}`);
    } else {
      dispatch(setCurrentFlat(fl!.flat!));
    }
  }, [project, floor, flat, projects]);

  return [project, floor, flat];
};