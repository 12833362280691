import axios, {Axios} from 'axios';


export class Api {
  static instance = new Api();

  private axios;

  constructor() {
    this.axios = new Axios({
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });
  }

  getExchangeRate = (from: string): Promise<number> => {
    return this.axios.get(`/api/rate?from=${from}`).then(a => a.data);
  };

}

export default Api.instance; 