import React from 'react';
import {useEffect, useState} from 'react';
import styled from '@emotion/styled/macro';
import {BREAKPOINTS} from '../../utils/styles/breakpoints';
import {BackButton} from '../../components/BackButton/BackButton';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
  selectCurrentFlat, selectCurrentProject,
} from '../../utils';
import {Trans, useTranslation} from 'react-i18next';
import {
  ComponentLStyling,
  ComponentMStyling,
  ComponentSStyling, ComponentXLStyling, ComponentXSStyling,
  HeadlineLStyling,
  HeadlineMStyling,
  HeadlineSStyling, HeadlineXLStyling,
  HeadlineXSStyling, ParagraphMStyling,
  ParagraphSStyling,
  ParagraphXSStyling,
  TextStyle,
} from '../../utils/styles/typography';
import {COLORS} from '../../utils/styles/colors';
import {ImageSlider} from './components/ImageSlider';
import {RoomIcons} from '../../components/Icons/RoomIcons';
import {useContentLoader} from '../Common/ContentLoader';
import {RoomItem} from './components/RoomItem';
import {Button} from '../../components/Button/Button';
import {HyperLink} from '../../components/HyperLink/HyperLink';
import {PDF} from '../../components/Icons/Icons';
import {useMediaMatch} from 'rooks';
import {Toggle} from '../../components/Toggle/Toggle';
import api from '../../utils/api';
import {CalculatorModal} from './components/CalculatorModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  ${BREAKPOINTS.MEDIUM}{
    padding: 0 32px;
  }

  ${BREAKPOINTS.XLARGE}{
    padding: 0 56px;
  }
  margin-bottom: 56px;

`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  
  ${BREAKPOINTS.MEDIUM}{
    flex-direction: row;
    margin-top: 20px;
  }
`;


const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  align-items: center;

  ${BREAKPOINTS.MEDIUM}{
    gap: 36px;
  }
  

  ${BREAKPOINTS.LARGE}{
    max-width: 340px;
    align-items: flex-start;
  }
  
  ${BREAKPOINTS.XLARGE}{
    max-width: 400px;
  }
`;

const Header = styled.div`

  display: flex;
  flex-direction: column;
  gap: 2px;
  
  align-items: center;

  ${BREAKPOINTS.LARGE}{
    align-items: flex-start;
  }

`;

const Title = styled.div`
  ${HeadlineMStyling(TextStyle.SemiBold, COLORS.Black)}
  
  ${BREAKPOINTS.MEDIUM}{
    ${HeadlineLStyling(TextStyle.SemiBold, COLORS.Black)}
  }
  
  ${BREAKPOINTS.XLARGE}{
    ${HeadlineXLStyling(TextStyle.SemiBold, COLORS.Black)}
  }
`;

const Description = styled.div`
  ${ComponentXSStyling(TextStyle.Regular, COLORS.Black)}
  
  ${BREAKPOINTS.MEDIUM}{
    ${ComponentSStyling(TextStyle.Regular, COLORS.Black)}
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 16px;
  width: 100%;
  
  ${BREAKPOINTS.MEDIUM}{
    gap: 56px;
  }

  ${BREAKPOINTS.LARGE}{
    justify-content: space-between;
  }
`;
const DetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  
  ${BREAKPOINTS.XLARGE}{
    gap: 8px;
    justify-content: flex-start;
  }
`;

const DetailsTitle = styled.div`
  ${ComponentXSStyling(TextStyle.Regular, COLORS.Neutral600)}
  ${BREAKPOINTS.MEDIUM}{
    ${ComponentSStyling(TextStyle.Regular, COLORS.Neutral600)}
  }
  ${BREAKPOINTS.XLARGE}{
    ${ComponentMStyling(TextStyle.Regular, COLORS.Neutral600)}
  }
`;

const DetailsMainContent = styled.div`
  display: flex;
  gap: 4px;
  align-items: baseline;

  ${HeadlineMStyling(TextStyle.Medium, COLORS.Primary700)}

  .secondary{
    ${HeadlineXSStyling(TextStyle.Regular, COLORS.Primary700)}
  }

  ${BREAKPOINTS.MEDIUM}{
    gap: 6px;
    ${HeadlineLStyling(TextStyle.Medium, COLORS.Primary700)}

    .secondary{
      ${HeadlineSStyling(TextStyle.Regular, COLORS.Primary700)}
    }
  }

  
  ${BREAKPOINTS.XLARGE}{
    gap: 6px;
    ${HeadlineXLStyling(TextStyle.Medium, COLORS.Primary700)}

    .secondary{
      ${HeadlineMStyling(TextStyle.Regular, COLORS.Primary700)}
    }
  }
  

`;

const DetailsNote = styled.div`
  display: flex;
  background-color: ${COLORS.Primary50};
  width: max-content;
  

  padding: 4px 8px;
  border-radius: 4px;
  ${ComponentXSStyling(TextStyle.Medium, COLORS.Primary600)}

  ${BREAKPOINTS.MEDIUM}{
    border-radius: 6px;
    padding: 6px 12px;
    ${ParagraphSStyling(TextStyle.Medium, COLORS.Primary600)}
  }  

  ${BREAKPOINTS.XLARGE}{
    padding: 8px 16px;
    border-radius: 8px;
    ${ParagraphMStyling(TextStyle.Medium, COLORS.Primary600)}
  }
`;


const Subtitle = styled.div`
  ${ComponentMStyling(TextStyle.SemiBold, COLORS.Neutral900)}
  
  ${BREAKPOINTS.XLARGE}{
    ${ComponentLStyling(TextStyle.SemiBold, COLORS.Neutral900)}
  }
`;


const RoomItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
`;

const RoomItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 16px;
  column-gap: 16px;
  
  ${BREAKPOINTS.LARGE}{
    justify-content: flex-start;
  }
`;

const Paragraph = styled.div`
  ${ParagraphSStyling(TextStyle.Regular, COLORS.Neutral700)}
  max-width: 60ch;
  text-align: center;
  margin-bottom: 8px;
  ${BREAKPOINTS.LARGE}{
     text-align: left;
  }
  ${BREAKPOINTS.XLARGE}{
    ${ParagraphMStyling(TextStyle.Regular, COLORS.Neutral700)}
  }
`;


const CurrencySwitcher = styled.div`
  display: flex;
  flex-direction: row-reverse;
  
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }
  align-items: center;
  gap: 16px;
  width: 100%;
`;
const Divider = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${COLORS.Neutral100};
`;

const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  
  ${BREAKPOINTS.LARGE}{
    margin-top: 20px;
    align-items: flex-start;
  }
`;

export const FlatPage = () => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const currentFlat = useSelector(selectCurrentFlat);
  const currentProject = useSelector(selectCurrentProject);
  const isLargeScreen = useMediaMatch(BREAKPOINTS.LARGE.replace('@media ', ''));

  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);
  const [rates, setRates] = useState([{value: 'USD', label: '$', rate: 1}, {value: 'GEL', label: '₾', rate: 1}]);
  const [currency, setCurrency] = useState('USD');

  const [project, floor, flat] = useContentLoader();

  useEffect(() => {
    api.getExchangeRate('USD').then((res) => {
      setRates([rates[0], {...rates[1], rate: res}]);
    });
  }, []);

  const rate = rates.find(a => a.value === currency)?.rate ?? 1;
  const currencySign = rates.find(a => a.value === currency)?.label ?? '$';
  const type = currentFlat?.type.title[i18n.language];
  const images = currentFlat?.type.views.map(a => a.image);
  const area = currentFlat?.area ?? 0;
  const height = currentFlat?.height ?? 2.9;
  const pricePerMeter = Math.round((currentFlat?.price?.price ?? currentProject?.flatPrice?.price ?? 0) * rate);
  const price = Math.floor(pricePerMeter * area);

  return (
    <>
      {
        !!currentFlat &&
        <Wrapper>
          <CalculatorModal isOpen={isCalculatorOpen}
                           onClose={() => setIsCalculatorOpen(false)}
                           rates={rates}
                           flat={currentFlat}
                           project={currentProject!}/>
          <BackButton label={t('დაბრუნდი სართულ {{floor}}', {floor: floor})}
                      action={() => navigate(`/project/${project}/${floor}`)}/>
          <ContentContainer>
            <MainContainer>

              <Header>
                <Title>{t('ბინა №{{flat}}', {flat})}</Title>
                <Description>{type}</Description>
              </Header>

              <CurrencySwitcher>
                <Divider/>
                <Toggle value={currency}
                        onChange={setCurrency}
                        items={rates}/>
              </CurrencySwitcher>

              <DetailsContainer>

                <DetailsContent>
                  <DetailsTitle>
                    {t('ბინის ფასი')}
                  </DetailsTitle>
                  <DetailsMainContent>
                    <span>{price.toLocaleString('en')}</span>
                    <span className={'secondary'}>{currencySign}</span>
                  </DetailsMainContent>
                  <DetailsNote>
                    {pricePerMeter} {currencySign}/{t('მ²')}
                  </DetailsNote>
                </DetailsContent>

                <DetailsContent>
                  <DetailsTitle>
                    {t('ფართობი')}
                  </DetailsTitle>
                  <DetailsMainContent>
                    <span>{area.toLocaleString('en')}</span>
                    <span className={'secondary'}>{t('მ²')}</span>
                  </DetailsMainContent>
                  <DetailsNote>
                    {height} {t('მ')}
                  </DetailsNote>
                </DetailsContent>

              </DetailsContainer>

              {
                !isLargeScreen &&
                <ImageContainer>
                  <ImageSlider images={images!}/>
                </ImageContainer>
              }
              <ParagraphContainer>
                <Subtitle>{t('სივრცის განაწილება')}</Subtitle>

                <RoomItemsContainer>
                  {currentFlat?.rooms?.map((room, index) => (
                    <RoomItemWrapper key={index}>
                      <RoomItem room={room}/>
                    </RoomItemWrapper>
                  ))}
                </RoomItemsContainer>
              </ParagraphContainer>

              <ParagraphContainer>
                <Subtitle>{t('ანგარიშსწორება')}</Subtitle>

                <Paragraph>
                  {t(
                    'მოქმედებს გადახდის ორი ტიპი: ერთიანი ანგარიშსწორება და შიდა განვადება. განვადების ტარიფის და პირობების სანახავად დააჭირეთ ღილაკს')}
                </Paragraph>

                <Button variant={'secondary'}
                        style={{width: 'fit-content', marginTop: '8px'}}
                        action={() => setIsCalculatorOpen(true)}
                        label={t('განვადების კალკულატორი')}/>
              </ParagraphContainer>

              <ParagraphContainer>
                <Subtitle>{t('არქიტექტურა და ჩაბარება')}</Subtitle>

                <Paragraph>
                  {t(
                    'ბინები ბარდება თეთრი და მწვანე კარკასული მდგომარეობით. ჭერის სიმაღლე 2.90 მეტრი. არქიტექტურული ნახაზის სანახავად გადმოწერეთ ფაილი')}
                </Paragraph>

                <HyperLink label={t('ნახაზის გადმოწერა')}
                           icon={<PDF/>}
                           href={currentFlat?.document}
                           target={'_blank'}/>

              </ParagraphContainer>

            </MainContainer>

            {
              isLargeScreen &&
              <ImageContainer>
                <ImageSlider images={images!}/>
              </ImageContainer>
            }
          </ContentContainer>
        </Wrapper>
      }
    </>
  );
};