import React, {useEffect, useRef, useState} from 'react';
import styled from '@emotion/styled';
import {useSelector} from 'react-redux';
import {Floor, selectCurrentFloor, selectCurrentProject} from '../../../utils';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useDimensionsRef, useMediaMatch} from 'rooks';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import sanity from '../../../utils/sanity';
import InlineSVG from 'react-inlinesvg';
import {COLORS} from '../../../utils/styles/colors';
import {FloorOverlay} from './FloorOverlay';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const BuildingContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  align-items: center;
  
  svg {
    [class]{
      cursor: pointer;
      fill: transparent;
    }
  }
  
  ${BREAKPOINTS.LARGE}{
    pointer-events: auto;
  }
`;


interface Props {
  onFloorSelected: (floor: string) => void;
  onActiveFloorChanged: (floor?: Floor) => void;
  activeFloor?: Floor;
}

export const FloorSelector: React.FunctionComponent<Props> = ({
                                                                onFloorSelected,
                                                                onActiveFloorChanged,
                                                                activeFloor,
                                                              }) => {
  const {t} = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [ref, dimensions] = useDimensionsRef();
  const [loading, setLoading] = useState(true);

  const currentProject = useSelector(selectCurrentProject);
  const svgRef = useRef<SVGElement>(null);

  useEffect(() => setLoading(!svgRef.current), [svgRef.current]);

  const buildingClickHandler = (event: React.MouseEvent) => {
    const container = (event.target as Element).classList[0];

    if (container) {
      const floor = currentProject?.floors.find(a => a.container === container);
      floor && onFloorSelected(floor.floor.floor);
    }
  };

  useEffect(() => {
    if (!loading) {
      const container = containerRef.current;
      const floors = Array.from(container!.querySelectorAll('svg [class]'));

      const onMouseEnter = (floor?: Floor) => onActiveFloorChanged(floor);
      const onMouseLeave = (e: any) => {
        const overlay = document.getElementById('overlay');
        if (e.toElement?.id !== 'overlay' && !overlay?.contains(e.toElement)) {
          onActiveFloorChanged(undefined);
        }
      };

      floors.forEach(floor => {
        const currentFloor = currentProject?.floors.find(a => a.container == floor.classList[0]);
        floor.addEventListener('mouseenter', onMouseEnter.bind(null, currentFloor?.floor));
        floor.addEventListener('mouseleave', onMouseLeave);
      });
    }

  }, [loading, currentProject]);

  const url = sanity.imageUrlBuilder(currentProject!.image.asset._ref).url();

  return (
    <Wrapper ref={containerRef}>
      <BuildingContainer ref={ref} onClick={buildingClickHandler}>
        {!!dimensions?.width && <InlineSVG innerRef={svgRef}
                                           src={url}
                                           onLoad={() => setLoading(false)}
                                           loader={<LoadingIndicator/>}
                                           width={dimensions.width}/>}
        <FloorOverlay floor={activeFloor}
                      onFloorSelected={onFloorSelected}
                      container={svgRef.current as any}
                      width={dimensions?.width}/>
      </BuildingContainer>
    </Wrapper>
  );
};