import SanityClientConstructor, {SanityClient} from '@sanity/client';
import ImageUrlBuilder from '@sanity/image-url';
import {HomePage, Project} from './types';

class Sanity {
  static instance: Sanity = new Sanity();

  private client: SanityClient;

  constructor() {
    this.client = SanityClientConstructor({
      projectId: process.env.REACT_APP_SANITY_PROJECTID!,
      dataset: process.env.REACT_APP_SANITY_DATASET || '',
      token: process.env.REACT_APP_SANITY_TOKEN || '',
      useCdn: false,
      apiVersion: '2021-03-25',
    });
  }

  imageUrlBuilder = (source: string) => ImageUrlBuilder(this.client).image(source);

  getProjects = async (): Promise<Project[]> => {
    return await this.client.fetch(
      '*[_type == "project"] { ..., "vegetation": vegetation.asset->url  , floors[]{ ..., floor{ ..., "document": document.asset->url, flats[]{ ..., flat{ ..., type->{...}, "document": document.asset->url  } } } } }');
  };

  getHomePage = async (): Promise<HomePage> => {
    return await this.client.fetch('*[_type == "homePage"]').then(a => a[0]);
  };
}


export default Sanity.instance;
