import React from 'react';
import {css} from '@emotion/react';
import {COLORS} from './colors';

const TYPOGRAPHY = {
  en: {
    primary: 'Inter, sans-serif',
    secondary: 'Inter Numeric, Source Sans Pro, sans-serif',
  },
  ka: {
    primary: 'Inter Numeric, Noto Sans Georgian, sans-serif',
    secondary: 'Inter Numeric, Noto Serif Georgian, sans-serif',
  },

};

enum FontFamily {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

enum TextStyle {
  Regular = 1,
  SemiBold,
  Medium,
  Italic,
}

const fontFamilyCss = (fontFamily: FontFamily) => {
  switch (fontFamily) {
    case FontFamily.Primary:
      return css`
        [lang=en] & {
          font-family: ${TYPOGRAPHY.en.primary};
        }
        &[lang=en] {
          font-family: ${TYPOGRAPHY.en.primary} !important;
        }

        [lang=ka] & {
          font-family: ${TYPOGRAPHY.ka.primary};
        }
        &[lang=ka] {
          font-family: ${TYPOGRAPHY.ka.primary}  !important;
        }

      `;
    case FontFamily.Secondary:
      return css`
        [lang=en] & {
          font-family: ${TYPOGRAPHY.en.secondary};
        }
        &[lang=en] {
          font-family: ${TYPOGRAPHY.en.secondary}  !important;
        }
        [lang=ka] & {
          font-family: ${TYPOGRAPHY.ka.secondary};
        }
        &[lang=ka] {
          font-family: ${TYPOGRAPHY.ka.secondary}  !important;
        }
`;
  }
};

const TextStyling = (
  fontSize: string,
  lineHeight: string,
  fontFamily: FontFamily,
  textStyle: TextStyle,
  color: string | undefined | null,
) => {
  return css`
    font-size: ${fontSize};
    font-weight: ${textStyle === TextStyle.Medium ? 500 : textStyle === TextStyle.SemiBold ? 600 : 400};
    line-height: ${lineHeight};
    font-style: ${textStyle && textStyle === TextStyle.Italic ? 'italic' : ''};
    ${color === null ? '' : `color: ${color || COLORS.Black};`}
    ${fontFamilyCss(fontFamily)}
  `;
};

const HeadlineXLStyling = (style?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '40px',
    '52px',
    family || FontFamily.Primary,
    style || TextStyle.SemiBold,
    color,
  );
const HeadlineLStyling = (style?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '32px',
    '40px',
    family || FontFamily.Primary,
    style || TextStyle.SemiBold,
    color,
  );
const HeadlineMStyling = (style?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '28px',
    '36px',
    family || FontFamily.Primary,
    style || TextStyle.SemiBold,
    color,
  );
const HeadlineSStyling = (style?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '24px',
    '32px',
    family || FontFamily.Primary,
    style || TextStyle.SemiBold,
    color,
  );
const HeadlineXSStyling = (style?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '20px',
    '24px',
    family || FontFamily.Primary,
    style || TextStyle.SemiBold,
    color,
  );
const HeadlineXXSStyling = (style?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '16px',
    '20px',
    family || FontFamily.Primary,
    style || TextStyle.SemiBold,
    color,
  );


const ComponentXLStyling = (textStyle?: TextStyle, color?: string | null, family?: FontFamily) => TextStyling(
  '24px',
  '28px',
  family || FontFamily.Primary,
  textStyle || TextStyle.Regular,
  color,
);

const ComponentLStyling = (textStyle?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '20px',
    '24px',
    family || FontFamily.Primary,
    textStyle || TextStyle.Regular,
    color,
  );

const ComponentMStyling = (textStyle?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '18px',
    '24px',
    family || FontFamily.Primary,
    textStyle || TextStyle.Regular,
    color,
  );

const ComponentSStyling = (textStyle?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '16px',
    '20px',
    family || FontFamily.Primary,
    textStyle || TextStyle.Regular,
    color,
  );

const ComponentXSStyling = (textStyle?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '14px',
    '16px',
    family || FontFamily.Primary,
    textStyle || TextStyle.Regular,
    color,
  );

const ComponentXXSStyling = (textStyle?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '12px',
    '16px',
    family || FontFamily.Primary,
    textStyle || TextStyle.Regular,
    color,
  );


const ParagraphXLStyling = (textStyle?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '24px',
    '36px',
    family || FontFamily.Secondary,
    textStyle || TextStyle.Regular,
    color,
  );
const ParagraphLStyling = (textStyle?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '20px',
    '32px',
    family || FontFamily.Secondary,
    textStyle || TextStyle.Regular,
    color,
  );
const ParagraphMStyling = (textStyle?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '18px',
    '28px',
    family || FontFamily.Secondary,
    textStyle || TextStyle.Regular,
    color,
  );
const ParagraphSStyling = (textStyle?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '16px',
    '20px',
    family || FontFamily.Secondary,
    textStyle || TextStyle.Regular,
    color,
  );
const ParagraphXSStyling = (textStyle?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '14px',
    '20px',
    family || FontFamily.Secondary,
    textStyle || TextStyle.Regular,
    color,
  );

const ParagraphXXSStyling = (textStyle?: TextStyle, color?: string | null, family?: FontFamily) =>
  TextStyling(
    '12px',
    '16px',
    family || FontFamily.Secondary,
    textStyle || TextStyle.Regular,
    color,
  );


export {TYPOGRAPHY};
export {TextStyle, FontFamily};
export {
  ComponentXLStyling,
  ComponentLStyling,
  ComponentMStyling,
  ComponentSStyling,
  ComponentXSStyling,
  ComponentXXSStyling,
};

export {
  ParagraphXLStyling,
  ParagraphLStyling,
  ParagraphMStyling,
  ParagraphSStyling,
  ParagraphXSStyling,
  ParagraphXXSStyling,
};

export {
  HeadlineXLStyling,
  HeadlineLStyling,
  HeadlineMStyling,
  HeadlineSStyling,
  HeadlineXSStyling,
  HeadlineXXSStyling,
};
