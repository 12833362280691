import {configureStore, createSlice} from '@reduxjs/toolkit';
import {Flat, Floor, HomePage, Project} from './types';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';

interface HomePageState {
  data?: HomePage;
  loading: boolean;
}

const initialHomePageState: HomePageState = {
  data: undefined,
  loading: true,
};

const homePageSlice = createSlice({
  name: 'homePage',
  initialState: initialHomePageState,
  reducers: {
    setHomePage: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
  },
});

interface AppState {
  loading?: boolean;
}

const initialAppState: AppState = {
  loading: true,
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    setLocalizationLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

interface ProjectsSate {
  loading?: boolean;
  projects: Project[];
  currentProject?: Project;
  currentFloor?: Floor;
  currentFlat?: Flat;
}

const initialProjectsState: ProjectsSate = {
  loading: true,
  projects: [],
  currentProject: undefined,
  currentFloor: undefined,
  currentFlat: undefined,
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState: initialProjectsState,
  reducers: {
    setProjects(state: ProjectsSate, action: { payload: Project[] }) {
      state.projects = action.payload;
      state.currentProject = action.payload[0];
      state.loading = !action.payload.length;
    },
    setCurrentProject(state: ProjectsSate, action: { payload: Project }) {
      state.currentProject = action.payload;
    },
    setCurrentFloor(state: ProjectsSate, action: { payload: Floor }) {
      state.currentFloor = action.payload;
    },
    setCurrentFlat(state: ProjectsSate, action: { payload: Flat }) {
      state.currentFlat = action.payload;
    },
  },
});

export const {setProjects, setCurrentProject, setCurrentFloor, setCurrentFlat} = projectsSlice.actions;
export const {setLocalizationLoading} = appSlice.actions;

export const {setHomePage} = homePageSlice.actions;

const store = configureStore({
  reducer: {
    projects: projectsSlice.reducer,
    app: appSlice.reducer,
    homePage: homePageSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const selectProjects = (state: RootState) => state.projects.projects;
export const selectCurrentProject = (state: RootState) => state.projects.currentProject;
export const selectCurrentFloor = (state: RootState) => state.projects.currentFloor;
export const selectCurrentFlat = (state: RootState) => state.projects.currentFlat;

export const selectLoading = (state: RootState) => state.projects.loading || state.app.loading || state.homePage.loading; 

export const selectHomePageData = (state: RootState) => state.homePage.data;

export const useProjectsDispatch = () => useDispatch<AppDispatch>();
export const useProjectsSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
