import React from 'react';
import styled from '@emotion/styled';
import {COLORS} from '../../../utils/styles/colors';
import {IconButton} from '../../Button/IconButton';
import {Close} from '../../Icons/Icons';
import {useDisableScroll} from '../../DisableScroll';
import {Logo} from '../Logo';
import {LanguageSwitcher} from './LanguageSwitcher';
import {ComponentSStyling, TextStyle} from '../../../utils/styles/typography';
import {shouldHandleButtonPress} from '../../../utils/helpers';
import {matchPath, useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectCurrentProject} from '../../../utils';

const Wrapper = styled.div`
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s ease-in-out;
  
  &.open{
    visibility: visible;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
`;

const Menu = styled.div`
  position: fixed;
  display: flex;
  visibility: hidden;
  transition: width 0.2s ease-in-out;
  overflow: hidden;
  width: 0;
  top: 0;
  right: 0;
  height: 100%;
  background-color: ${COLORS.White};
  z-index: 1010;

  .open &{
    width: 320px;
    visibility: visible;
  }
`;


const Container = styled.nav`
  display: flex;
  flex-direction: column;
  width: 320px;
  flex-flow: column;
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${COLORS.Neutral200};

  
  ${ComponentSStyling(TextStyle.SemiBold, COLORS.Neutral900)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  flex: 1;
  gap: 8px;
`;

const LanguageContainer = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid ${COLORS.Neutral200};
`;

const NavigationItem = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 16px;
  padding-left: 20px;
  min-height: 48px;
  position: relative;
  ${ComponentSStyling(TextStyle.Medium, COLORS.Neutral600)};

  &:hover {
    color: ${COLORS.Primary700};
    background-color: ${COLORS.Neutral50};
  }
  
  &:active{
    color: ${COLORS.Primary900};
    background-color: ${COLORS.Primary50};
  }
  
  &.active{
    color: ${COLORS.Primary700};
    &:before{
      content: '';
      padding: 16px 0;
      box-sizing: border-box;
      position: absolute;
      left: 8px;
      height: 36px;
      width: 4px;
      background-color: ${COLORS.Primary900};
      border-radius: 4px;
    }
  }
`;

interface MobileMenuProps {
  open: boolean;
  onClose: () => void;
}

export const MobileMenu = ({open, onClose}: MobileMenuProps) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useDisableScroll(open);

  const currentProject = useSelector(selectCurrentProject);

  const isPathActive = (path: string) => {
    const match = matchPath(path, location.pathname);
    return match !== null;
  };

  const handleNavigationClick = (path: string) => {
    navigate(path);
    onClose();
  };

  return (
    <Wrapper className={open ? 'open' : ''} onScroll={e => e.preventDefault()} onClick={e => {
      e.stopPropagation();
      onClose();
    }}>
      <Menu onClick={e => e.stopPropagation()}>
        <Container>
          <Header>
            <Logo/>
            <IconButton action={onClose} icon={<Close/>}/>
          </Header>
          <Content>
            <NavigationItem tabIndex={0}
                            className={isPathActive('/') ? 'active' : ''}
                            onClick={() => handleNavigationClick('/')}
                            onKeyDown={(e) => shouldHandleButtonPress(e) && handleNavigationClick('/')}>
              {t('Navigation.Home')}
            </NavigationItem>
            <NavigationItem tabIndex={0}
                            className={isPathActive('/project/*') ? 'active' : ''}
                            onClick={() => handleNavigationClick(`/project/${currentProject?.slug.current}`)}
                            onKeyDown={(e) => shouldHandleButtonPress(e) && handleNavigationClick(`/project/${currentProject?.slug.current}`)}>
              {t('Navigation.Apartments')}
            </NavigationItem>
          </Content>
          <LanguageContainer>
            <LanguageSwitcher/>
          </LanguageContainer>
        </Container>
      </Menu>
    </Wrapper>

  );
};