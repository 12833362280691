import React from 'react';
import styled from '@emotion/styled/macro';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import {IconButton} from '../../../components/Button/IconButton';
import {ChevronDown, ChevronLeft, ChevronRight, ChevronUp} from '../../../components/Icons/Icons';
import {Floor, selectCurrentFloor, selectCurrentProject} from '../../../utils';
import {useSelector} from 'react-redux';
import {ComponentMStyling, ParagraphXSStyling, TextStyle} from '../../../utils/styles/typography';
import {COLORS} from '../../../utils/styles/colors';
import {useTranslation} from 'react-i18next';
import {useMediaMatch} from 'rooks';
import {act} from 'react-dom/test-utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  
  
  ${BREAKPOINTS.LARGE}{
    display: none;
  }
`;

const FloorButtonMainText = styled.div`
  ${ComponentMStyling(TextStyle.Regular, COLORS.Neutral400)}
`;

const FloorButtonNoteText = styled.div`
  ${ParagraphXSStyling(TextStyle.Regular, COLORS.Neutral400)}
`;

const FloorButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 6px;
  border-radius: 6px;
  
  ${BREAKPOINTS.LARGE}{
    padding: 6px 12px;
  }
`;

const FloorButton = styled.button`
  display: flex;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  user-select: none;
  
  &.adj{
    ${FloorButtonMainText}{
     color: ${COLORS.Neutral600};
    }
    ${FloorButtonNoteText}{
     color: ${COLORS.Neutral600};
    }
  }
  
  &.active{
    ${FloorButtonMainText}{
     ${ComponentMStyling(TextStyle.Medium, COLORS.Primary700)}
    }
    ${FloorButtonNoteText}{
     color: ${COLORS.Primary700};
    }
    ${FloorButtonContent}{
      background-color: ${COLORS.Primary50};    
    }
  }

  &.active:hover{
    ${FloorButtonMainText}{
     color: ${COLORS.Primary900};
    }
    ${FloorButtonNoteText}{
     color: ${COLORS.Primary900};
    }
  }
  
  &:hover {
    ${FloorButtonMainText}{
     ${ComponentMStyling(TextStyle.Medium, COLORS.Primary700)}
    }
    ${FloorButtonNoteText}{
     color: ${COLORS.Primary700};
    }
  }
  
  &:active {
    ${FloorButtonMainText}{
     ${ComponentMStyling(TextStyle.Medium, COLORS.Primary700)}
    }
    ${FloorButtonNoteText}{
     color: ${COLORS.Primary700};
    }
    ${FloorButtonContent}{
      background-color: ${COLORS.Primary50};    
    }
  }
`;


interface Props {
  onActiveFloorChanged: (floor: Floor) => void;
  activeFloor?: Floor;
}

export const BuildingNavigation: React.FunctionComponent<Props> = ({onActiveFloorChanged, activeFloor}) => {
  const {t} = useTranslation();

  const currentProject = useSelector(selectCurrentProject);
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const lastFloor = currentProject!.floors[currentProject!.floors.length - 1].floor.floor === activeFloor?.floor;
  const firstFloor = currentProject!.floors[0].floor.floor === activeFloor?.floor;
  const currentIndex = currentProject!.floors.findIndex(a => a.floor.floor === activeFloor?.floor);

  let nextFloor = currentProject!.floors[currentIndex >= 0 ? currentIndex! + 1 : 0]?.floor;
  let prevFloor = currentProject!.floors[currentIndex >= 0
                                         ? currentIndex! - 1
                                         : currentProject!.floors.length - 1]?.floor;

  const count = isMediumScreen ? 2 : 1;
  let itemsFrom = Math.max(currentIndex - count, 0);
  let itemsTo = Math.min(currentIndex + count, currentProject!.floors.length - 1);

  itemsTo += itemsFrom - (currentIndex - count);
  itemsFrom -= currentIndex + count - Math.min(currentIndex + count, currentProject!.floors.length - 1);

  const floors = currentProject!.floors.slice(itemsFrom, itemsTo + 1);
  
  return (
    <Wrapper>
      <IconButton action={() => onActiveFloorChanged(prevFloor)}
                  icon={<ChevronLeft/>}
                  disabled={firstFloor}/>

      {floors.map(floor => (
        <FloorButton key={floor.floor.floor}
                     onClick={() => onActiveFloorChanged(floor.floor)}
                     className={floor.floor.floor === activeFloor?.floor
                                ? 'active'
                                : floor.floor.floor === nextFloor?.floor || floor.floor.floor === prevFloor?.floor
                                  ? 'adj'
                                  : ''}>
          <FloorButtonContent>
            <FloorButtonMainText>
              {floor.floor.floor}
            </FloorButtonMainText>
            <FloorButtonNoteText>
              {t(`FloorType.${floor.floor.type}`)}
            </FloorButtonNoteText>
          </FloorButtonContent>
        </FloorButton>
      ))}

      <IconButton action={() => onActiveFloorChanged(nextFloor)}
                  icon={<ChevronRight/>}
                  disabled={lastFloor}/>

    </Wrapper>
  );
};