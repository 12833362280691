import React from 'react';
import styled from '@emotion/styled/macro';
import {COLORS} from '../../utils/styles/colors';
import {SHADOWS} from '../../utils/styles/shadows';
import {ComponentSStyling, ComponentXSStyling, TextStyle} from '../../utils/styles/typography';
import {BREAKPOINTS} from '../../utils/styles/breakpoints';
import {defaultOnMouseDownHandler} from '../../utils/helpers';

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 36px;
  height: 36px;
  border-radius: 50%;
  box-sizing: border-box;
  color: ${COLORS.Neutral500};
`;

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding: none;
  cursor: pointer;
  border-radius: 2px;
  width: 48px;
  height: 48px;
  
  &:hover{
    ${ContentContainer}{
      background-color: ${COLORS.Primary50};
      color: ${COLORS.Primary600};
    }
  }

  &:active{
    ${ContentContainer}{
      background-color: ${COLORS.Primary100};
      color: ${COLORS.Primary900};
    }
  }

  &:focus{
   outline-offset: 2px; 
   outline: 2px solid ${COLORS.Black};
  }
  
  &:disabled{
   cursor: not-allowed;
   pointer-events: none;
   ${ContentContainer}{
     opacity: 50%;
   }
  }
`;

interface IconButtonProps {
  action: () => void;
  icon: React.ReactElement;
  disabled?: boolean;
}

export const IconButton: React.FunctionComponent<IconButtonProps> = ({action, icon, disabled}) => {
  return (
    <Container onClick={action} disabled={disabled} onMouseDown={defaultOnMouseDownHandler}>
      <ContentContainer>
        {React.cloneElement(icon, {width: 24, height: 24})}
      </ContentContainer>
    </Container>
  );
};