import React from 'react';
import styled from '@emotion/styled';
import {Logo} from '../Logo';
import {NavigationMenu} from './NavigationMenu';
import {Button} from '../../Button/Button';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import {LanguageSwitcher} from './LanguageSwitcher';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {defaultOnMouseDownHandler, shouldHandleButtonPress} from '../../../utils/helpers';
import {COLORS} from '../../../utils/styles/colors';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 140px;
  
  span {
    cursor: pointer;
    width: 48px;
    height: 48px;
    &:focus{
      outline-offset: 4px;
      outline: 2px solid ${COLORS.Black};
    }
  }
`;

const NavigationContainer = styled.div`
  display: none;
  align-items: center;

  ${BREAKPOINTS.MEDIUM}{
    display: flex;
  }
`;

const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ContactButtonContainer = styled.div`
  display: flex;
  width: 140px;
`;

const RightContainer = styled.div`
  display: none;
  align-items: center;
  gap: 8px;
  
  ${BREAKPOINTS.MEDIUM}{
    display: flex;
  }
`;

export const DesktopMenu = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <LogoContainer>
        <span onClick={() => navigate('/')}
              tabIndex={0}
              onMouseDown={defaultOnMouseDownHandler}
              onKeyDown={e => shouldHandleButtonPress(e) && navigate('/')}>
          <Logo/>
        </span>
      </LogoContainer>
      <NavigationContainer>
        <NavigationMenu/>
      </NavigationContainer>
      <RightContainer>
        <LanguageContainer>
          <LanguageSwitcher/>
        </LanguageContainer>
        <ContactButtonContainer>
          <Button action={() => navigate('#contact')}
                  label={t('კონტაქტი')}
                  variant={'filled'}
          />
        </ContactButtonContainer>
      </RightContainer>
    </Container>
  );
};