import React from 'react';
import styled from '@emotion/styled/macro';
import {ComponentSStyling, ComponentXSStyling, TextStyle} from '../../utils/styles/typography';
import {COLORS} from '../../utils/styles/colors';
import {ArrowLeftLong} from '../Icons/Icons';
import {BREAKPOINTS} from '../../utils/styles/breakpoints';


const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 4px 4px 2px;

  min-height: 32px;
  border-radius: 4px;
  box-sizing: border-box;
  
  ${ComponentXSStyling(TextStyle.Medium, COLORS.Primary500)}
 
  ${BREAKPOINTS.MEDIUM}{
    ${ComponentSStyling(TextStyle.Medium, null)}
  }
`;

const Container = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
  min-height: 48px;
  width: fit-content;

  
  &:hover{
    ${ContentContainer}{
      background-color: ${COLORS.Primary50};
      color: ${COLORS.Primary700};
    }
  }
  
  &:active{
    ${ContentContainer}{
      background-color: ${COLORS.Primary100};
      color: ${COLORS.Primary900};
    }
  }
  
  &:focus{
    outline-offset: 4px;
  }
`;


interface BackButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  label: string;
  action: () => void;
}

export const BackButton: React.FunctionComponent<BackButtonProps> = ({label, action, ...rest}) => {

  return (
    <Container onClick={action} {...rest}>
      <ContentContainer>
        <ArrowLeftLong width={'16px'} height={'16px'}/>
        <span>{label}</span>
      </ContentContainer>
    </Container>
  );
};
