import React from 'react';
import styled from '@emotion/styled/macro';
import {useSwipeable} from 'react-swipeable';
import {COLORS} from '../../../utils/styles/colors';
import {Asset} from '../../../utils';
import sanity from '../../../utils/sanity';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import {useDimensionsRef, useMediaMatch} from 'rooks';
import {defaultOnMouseDownHandler} from '../../../utils/helpers';
import Lightbox, {ImagesListType} from 'react-spring-lightbox';
import {ImagesListItem} from 'react-spring-lightbox/dist/types/ImagesList';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  ${BREAKPOINTS.MEDIUM}{
    gap: 24px;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  max-height: 492px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;

  &:after{
    content: '\\2315';
    position: absolute;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background-color: rgba(0, 0, 0, 0.0);
    transition: all 0.3s ease-in-out;
    color: rgba(0, 0, 0, 0.0);
    border-radius: 4px;
  }
  
  &:hover:after{
    color: ${COLORS.White};
    font-size: 48px;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const Image = styled.img`
  object-fit: contain;
  display: none;
  cursor: pointer;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  &.active {
    display: block;
  }
`;

const ProgressContainer = styled.div`
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   gap: 8px;
`;

const ImageToggleContainer = styled.div`
  background-color: ${COLORS.Neutral50};
  cursor: pointer;
  width: 48px;
  height: 48px;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  
  ${BREAKPOINTS.MEDIUM}{
    width: 72px;
    height: 72px;
  }

  ${BREAKPOINTS.LARGE}{
    width: 88px;
    height: 88px;
  }

  ${BREAKPOINTS.XLARGE}{
    border-radius: 8px;
    padding: 12px;
    width: 88px;
    height: 88px;
  }
`;


const ImageToggleButton = styled.button`
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background: none;

  &:focus {
    outline-offset: 2px;
    border-radius: 4px;
    outline: 2px solid ${COLORS.Black};
  }

  &.active {
   ${ImageToggleContainer}{
      background-color: ${COLORS.Primary50};
   }
  }

  &:hover {
   ${ImageToggleContainer}{
    box-shadow: inset  0 0 0 1px ${COLORS.Primary600};
    ${BREAKPOINTS.XLARGE}{
      box-shadow: inset  0 0 0 2px ${COLORS.Primary600};
    }
   }
  }

  &:active {
   ${ImageToggleContainer}{
      background-color: ${COLORS.Primary100};
      box-shadow: inset  0 0 0 1px ${COLORS.Primary700};
      ${BREAKPOINTS.XLARGE}{
        box-shadow: inset  0 0 0 2px ${COLORS.Primary700};
      }
   }
  }
`;


const ImageToggle = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

interface ImageSliderProps {
  images: Asset[];
}

export const ImageSlider: React.FunctionComponent<ImageSliderProps> = ({images}) => {
  const [current, setCurrent] = React.useState(0);
  const [fullscreen, setFullscreen] = React.useState(false);

  const [ref, dimensions] = useDimensionsRef();

  const handlers = useSwipeable({
    onSwipedLeft: (e) => {
      setCurrent(Math.min(images.length - 1, current + 1));
    },
    onSwipedRight: (e) => {
      setCurrent(Math.max(0, current - 1));
    },
  });

  const thumbnails =
    images.map(image => sanity.imageUrlBuilder(image.asset._ref)
      .size(64, 64)
      .format('png')
      .fit('scale')
      .url());

  const gallery = images.map(image => sanity.imageUrlBuilder(image.asset._ref)
    .width(+(dimensions?.width ?? 492).toFixed(0))
    .format('png')
    .fit('scale')
    .url());

  const lightbox = images.map(image => ({
    src: sanity.imageUrlBuilder(image.asset._ref).format('png').url(),
    alt: image.altText,
    loading: 'lazy',
  } as ImagesListItem));

  return (
    <Wrapper {...handlers}>
      <ImageContainer ref={ref}>
        {images.map((image, index) => (
          <Image key={index}
                 onClick={() => setFullscreen(true)}
                 className={current === index ? 'active' : ''}
                 src={gallery[index]}
                 alt={image.altText}/>
        ))}
      </ImageContainer>
      <ProgressContainer>
        {images.map((image, index) => (
          <ImageToggleButton key={index}
                             className={current === index ? 'active' : ''}
                             onMouseDown={defaultOnMouseDownHandler}
                             onClick={() => setCurrent(index)}
                             tabIndex={0}>
            <ImageToggleContainer>
              <ImageToggle
                src={thumbnails[index]}
                alt={image.altText}/>
            </ImageToggleContainer>
          </ImageToggleButton>
        ))}
      </ProgressContainer>

      <Lightbox isOpen={fullscreen}
                images={lightbox}
                style={{background: 'rgba(0,0,0,0.8)'}}
                currentIndex={current}
                onNext={() => setCurrent(Math.min(images.length - 1, current + 1))}
                onPrev={() => setCurrent(Math.max(0, current - 1))}
                onClose={() => setFullscreen(false)}/>
    </Wrapper>
  );

};