import React from 'react';
import {useSelector} from 'react-redux';
import {selectCurrentProject, selectHomePageData} from '../../../utils';
import styled from '@emotion/styled';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import {Header} from '../../../components/Header/Header';
import {HeaderText, ParagraphText} from '../styles';
import {Trans, useTranslation} from 'react-i18next';
import {Button} from '../../../components/Button/Button';
import {useNavigate} from 'react-router-dom';
import {useMediaMatch} from 'rooks';
import sanity from '../../../utils/sanity';
import {COLORS} from '../../../utils/styles/colors';

const Background = styled.div`
  background-color: ${COLORS.White};
  padding: 16px 16px;
  ${BREAKPOINTS.MEDIUM}{
    padding: 16px 32px;
  }

  ${BREAKPOINTS.MEDIUM}{
    padding: 28px 32px;
  }

  ${BREAKPOINTS.XLARGE}{
    padding: 28px 56px;
    padding-bottom:56px;
  }
`;


const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;


const ImageContainer = styled.div`
  display: flex;
  img{
    max-width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  
  ${BREAKPOINTS.LARGE}{
    max-width: 400px;
    align-items: flex-start;
    gap: 56px;
  }
`;


export const ApartmentSection = () => {

  const {t} = useTranslation();
  const data = useSelector(selectHomePageData);

  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const isXLargeScreen = useMediaMatch(BREAKPOINTS.XLARGE.replace('@media ', ''));

  const navigate = useNavigate();
  const currentProject = useSelector(selectCurrentProject);

  const handleClick = () => {
    navigate(`/project/${currentProject?.slug.current}`);
  };

  const height = isXLargeScreen
                 ? 512
                 : isMediumScreen
                   ? 456
                   : 412;

  return <Background>
    <Wrapper>
      <ContentContainer>
        <HeaderText>
          <Trans ns={'Common'} i18nKey={'HomePageApartmentHeader'}>
            იპოვე <i>სახლი</i> პი აპარტმენტში
          </Trans>
        </HeaderText>
        <ParagraphText>
          <Trans ns={'Common'} i18nKey={'HomePageApartmentDescription'}>
            სახლი - 5000 მ2 საკუთარი რეკრეაციული ზონით, ფეხბურთისა და კალათბურთის მოედნით, ღია და დახურული
            ავტოსადგომებით.
            კორპუსი შედგება 17 სართულისგან, მიწის ქვეშ 3 სართული მოიცავს დახურულ ავტოსადგომს.
          </Trans>
        </ParagraphText>
        <Button action={handleClick} label={t('შეარჩიე ბინა')}/>
      </ContentContainer>
      <ImageContainer>
        <img alt={data?.apartment.altText}
             src={sanity.imageUrlBuilder(data!.apartment!.asset._ref).height(height).format('png').fit('scale').url()}/>

      </ImageContainer>
    </Wrapper>
  </Background>;
};