import React from 'react';
import {useContentLoader} from '../Common/ContentLoader';
import {BREAKPOINTS} from '../../utils/styles/breakpoints';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectCurrentFloor} from '../../utils';
import styled from '@emotion/styled';
import {BackButton} from '../../components/BackButton/BackButton';
import {COLORS} from '../../utils/styles/colors';
import {
  ComponentSStyling,
  ComponentXSStyling,
  HeadlineLStyling,
  HeadlineMStyling,
  HeadlineSStyling,
  HeadlineXLStyling,
  TextStyle,
} from '../../utils/styles/typography';
import {FlatSelector} from './components/FlatSelector';
import {FloorNavigation} from './components/FloorNavigation';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  ${BREAKPOINTS.MEDIUM}{
    padding: 0 32px;
  }

  ${BREAKPOINTS.XLARGE}{
    padding: 0 56px;
  }
  margin-bottom: 56px;

`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Header = styled.div`

  display: flex;
  flex-direction: column;
  gap: 2px;
  
  align-items: center;

  ${BREAKPOINTS.LARGE}{
    align-items: flex-start;
  }

`;

const Title = styled.div`
  ${HeadlineMStyling(TextStyle.SemiBold, COLORS.Black)}
  
  ${BREAKPOINTS.MEDIUM}{
    ${HeadlineLStyling(TextStyle.SemiBold, COLORS.Black)}
  }
  
  ${BREAKPOINTS.XLARGE}{
    ${HeadlineXLStyling(TextStyle.SemiBold, COLORS.Black)}
  }
`;

const Description = styled.div`
  ${ComponentXSStyling(TextStyle.Regular, COLORS.Black)}
  
  ${BREAKPOINTS.MEDIUM}{
    ${ComponentSStyling(TextStyle.Regular, COLORS.Black)}
  }
`;


const FloorContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  
  ${BREAKPOINTS.LARGE}{
    gap: 96px;
    flex-direction: row;
  }
`;

export const FloorPage = () => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const currentFloor = useSelector(selectCurrentFloor);
  const [project, floor] = useContentLoader();


  const onFlatSelected = (flat: number) => {
    navigate(`/project/${project}/${floor}/${flat}`);
  };

  const onFloorSelected = (floor: string) => {
    navigate(`/project/${project}/${floor}`);
  };

  return (
    <>
      {
        !!currentFloor &&
          <Wrapper>
              <BackButton label={t('დაბრუნდი შენობაზე')}
                          action={() => navigate(`/project/${project}`)}/>
              <ContentContainer>

                  <Header>
                    <Title>{t('სართული №{{floor}}', {floor})}</Title>
                    <Description>{t('შეარჩიე ბინა')}</Description>
                  </Header>
                  <FloorContainer>
                      <FloorNavigation onFloorSelected={onFloorSelected}/>
                      <FlatSelector onFlatSelected={onFlatSelected}/>
                  </FloorContainer>
              </ContentContainer>

          </Wrapper>
      }
    </>
  );
};