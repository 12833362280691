import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import styled from '@emotion/styled/macro';
import {ComponentSStyling, TextStyle} from '../../../utils/styles/typography';
import {COLORS} from '../../../utils/styles/colors';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import {SHADOWS} from '../../../utils/styles/shadows';
import {defaultOnMouseDownHandler} from '../../../utils/helpers';

const Container = styled.div`
  position: relative;
`;

const CurrentLanguage = styled.div`
  display: flex;
  align-items: center;
  right: 0;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  width: 3ch;
  max-width: 3ch;
  text-overflow: clip;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  
  [lang='ka'] & {
    max-width: 4ch;
    width: 4ch;
    letter-spacing: 4px;
  }
`;

const LanguageOption = styled.div`
  ${ComponentSStyling(TextStyle.SemiBold, COLORS.Neutral500)};
  flex: 1;
  
  &:hover{
   color: ${COLORS.Neutral700};
  }
  
  &:active{
    color: ${COLORS.Neutral900};
  }
`;

const LanguageOptions = styled.div`
  max-height: 0;
  max-width: 3ch;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  margin-top: 10px;
  display: flex;
`;

const LanguageDropdown = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  padding: 16px 16px 12px 16px;
  max-width: 3ch;
  transition: all 0.3s ease-in-out;
`;

const LanguageButton = styled.div`
  display: flex;
  z-index: 1;
  position: relative;
  cursor: pointer;
  ${ComponentSStyling(TextStyle.SemiBold, COLORS.Primary900)};
  width: 0;
  height: 48px;
  
  &:hover {
    ${LanguageOptions}{
      max-height: 100px;
      display: flex;
      ${BREAKPOINTS.MEDIUM}{
        max-width: 100px !important;
      }
    }
    ${LanguageDropdown}{
      box-shadow: ${SHADOWS.ShadowCentered};
      ${BREAKPOINTS.MEDIUM}{
        max-width: 100px !important;
      }
    }
   ${CurrentLanguage} {
      ${BREAKPOINTS.MEDIUM}{
        width: 100px !important;
        max-width: 100px !important;
      }
     letter-spacing: unset;
    }
  }
`;

export const LanguageSwitcher = () => {
  const {i18n, t} = useTranslation();

  const languages = ['ka', 'en'];

  return (
    <Container>
      <LanguageButton>
        <LanguageDropdown>
          <CurrentLanguage lang={i18n.language}>
            {t(`Language.${i18n.language}`)}
          </CurrentLanguage>
          <LanguageOptions>
            {
              languages.filter(a => a != i18n.language).map(lang =>
                <LanguageOption key={lang}
                                lang={lang}
                                onClick={() => i18n.changeLanguage(lang)}>
                  {t(`Language.${lang}`)}
                </LanguageOption>,
              )
            }
          </LanguageOptions>
        </LanguageDropdown>
      </LanguageButton>
    </Container>

  );
};