import styled from '@emotion/styled';
import {COLORS} from '../../utils/styles/colors';
import {
  HeadlineLStyling,
  HeadlineMStyling,
  HeadlineXLStyling, ParagraphLStyling,
  ParagraphMStyling,
  TextStyle,
} from '../../utils/styles/typography';
import {BREAKPOINTS} from '../../utils/styles/breakpoints';


export const HeaderText = styled.div`
  text-align: center;

  i {
   font-style: normal;
   color: ${COLORS.Primary200};
  }

  ${HeadlineMStyling(TextStyle.SemiBold, COLORS.Black)}
  
  ${BREAKPOINTS.LARGE}{
    ${HeadlineXLStyling(TextStyle.SemiBold, COLORS.Black)}
    text-align: left;
  }
`;

export const ParagraphText = styled.div`
  ${ParagraphMStyling(TextStyle.Regular, COLORS.Neutral600)}
  max-width: 50ch;
  text-align: center;
  
  ${BREAKPOINTS.LARGE}{
    ${ParagraphLStyling(TextStyle.Regular, COLORS.Neutral600)}
    text-align: left;
  }
`;
