import React from 'react';
import styled from '@emotion/styled/macro';
import {COLORS} from '../../../utils/styles/colors';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import {
  ComponentMStyling,
  ComponentSStyling, ParagraphMStyling,
  ParagraphSStyling,
  ParagraphXSStyling,
  TextStyle,
} from '../../../utils/styles/typography';
import {RoomIcons} from '../../../components/Icons/RoomIcons';
import {useTranslation} from 'react-i18next';
import {Room} from '../../../utils';


const RoomItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  min-width: 96px;
  cursor: pointer;

  box-sizing: border-box;

  ${BREAKPOINTS.MEDIUM}{
    gap: 10px;
  }
  
  ${BREAKPOINTS.XLARGE}{
    min-width: 110px;
  }
`;

const RoomItemIcon = styled.div`
  color: ${COLORS.Primary200};
  width: 64px;
  height: 64px;
  svg {
    width: 64px;
    height: 64px;
  }  
  transition: color 0.2s ease-in-out;
  
  ${RoomItemWrapper}:hover & {
    color: ${COLORS.Primary500};
  }
  
  ${BREAKPOINTS.MEDIUM}{
    width: 76px;
    height: 76px;
    svg {
      width: 76px;
      height: 76px;
    }  
  }
  
  ${BREAKPOINTS.XLARGE}{
    width: 88px;
    height: 88px;
    svg {
      width: 88px;
      height: 88px;
    }  
  }
`;


const RoomItemText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  color: ${COLORS.Primary700};
`;

const RoomItemArea = styled.div`
  transition: color 0.2s ease-in-out;
  ${ComponentSStyling(TextStyle.Medium, COLORS.Primary700)}
  ${BREAKPOINTS.XLARGE}{
    ${ComponentMStyling(TextStyle.Medium, COLORS.Primary700)}
  }
`;

const RoomItemName = styled.div`
  transition: color 0.2s ease-in-out;
  ${ParagraphXSStyling(TextStyle.Regular, 'transparent')}
  
  ${BREAKPOINTS.XLARGE}{
    ${ParagraphSStyling(TextStyle.Regular, 'transparent')}
  }
  
  ${RoomItemWrapper}:hover & {
    color: ${COLORS.Neutral900};
  }
`;


export const RoomItem: React.FunctionComponent<{ room: Room }> = ({room}) => {
  const {t} = useTranslation();

  return (
    <RoomItemWrapper>
      <RoomItemIcon>
        {RoomIcons[room.type]}
      </RoomItemIcon>
      <RoomItemText>
        <RoomItemArea>
          {`${room.area} m²`}
        </RoomItemArea>
        <RoomItemName>
          {t(`RoomType.${room.type}`)}
        </RoomItemName>
      </RoomItemText>
    </RoomItemWrapper>
  );
};