import React from 'react';
import styled from '@emotion/styled';
import {Header} from './Header/Header';
import LoadingIndicator from './LoadingIndicator/LoadingIndicator';
import {BREAKPOINTS} from '../utils/styles/breakpoints';
import {Footer} from './Footer/Footer';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-flow: column;
`;

const Wrapper = styled.div`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  width: 100%;
  
  box-sizing: border-box;
  margin-top: 8px;

  &.loading{
    height: 100vh;
  }
`;

const ContentContainer = styled.div`
  margin-top: 16px;
  ${BREAKPOINTS.LARGE}{
    margin-top: 32px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

interface LayoutProps {
  loading: boolean;
  children: React.ReactNode;
}

export const Layout: React.FunctionComponent<LayoutProps> = ({loading, children}) => {
  return (
    <Container>
      <Wrapper className={loading ? 'loading' : ''}>
        {
          loading &&
            <LoadingContainer>
                <LoadingIndicator/>
            </LoadingContainer>
        }
        {
          !loading &&
            <>
                <Header/>
                <ContentContainer>
                  {children}
                </ContentContainer>
            </>
        }
      </Wrapper>
      {
        !loading && <Footer/>
      }
    </Container>
  );
};