import React from 'react';
import styled from '@emotion/styled/macro';
import { BREAKPOINTS } from '../../../utils/styles/breakpoints';
import { IconButton } from '../../../components/Button/IconButton';
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from '../../../components/Icons/Icons';
import { selectCurrentFloor, selectCurrentProject } from '../../../utils';
import { useSelector } from 'react-redux';
import { ComponentMStyling, ParagraphXSStyling, TextStyle } from '../../../utils/styles/typography';
import { COLORS } from '../../../utils/styles/colors';
import { useTranslation } from 'react-i18next';
import { useMediaMatch } from 'rooks';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 16px;
  
  
  ${BREAKPOINTS.LARGE}{
    justify-content: flex-start;
    gap: 8px;
    flex-direction: column;
  }
  
  ${BREAKPOINTS.XLARGE}{
    gap: 12px;
  }
`;

const FloorButtonMainText = styled.div`
  ${ComponentMStyling(TextStyle.Regular, COLORS.Neutral400)}
`;

const FloorButtonNoteText = styled.div`
  ${ParagraphXSStyling(TextStyle.Regular, COLORS.Neutral400)}
`;

const FloorButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 6px;
  border-radius: 6px;
  
  ${BREAKPOINTS.LARGE}{
    padding: 6px 12px;
  }
`;

const FloorButton = styled.button`
  display: flex;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  user-select: none;
  
  &.adj{
    ${FloorButtonMainText}{
     color: ${COLORS.Neutral600};
    }
    ${FloorButtonNoteText}{
     color: ${COLORS.Neutral600};
    }
  }
  
  &.active{
    ${FloorButtonMainText}{
     ${ComponentMStyling(TextStyle.Medium, COLORS.Primary700)}
    }
    ${FloorButtonNoteText}{
     color: ${COLORS.Primary700};
    }
    ${FloorButtonContent}{
      background-color: ${COLORS.Primary50};    
    }
  }

  &.active:hover{
    ${FloorButtonMainText}{
     color: ${COLORS.Primary900};
    }
    ${FloorButtonNoteText}{
     color: ${COLORS.Primary900};
    }
  }
  
  &:hover {
    ${FloorButtonMainText}{
     ${ComponentMStyling(TextStyle.Medium, COLORS.Primary700)}
    }
    ${FloorButtonNoteText}{
     color: ${COLORS.Primary700};
    }
  }
  
  &:active {
    ${FloorButtonMainText}{
     ${ComponentMStyling(TextStyle.Medium, COLORS.Primary700)}
    }
    ${FloorButtonNoteText}{
     color: ${COLORS.Primary700};
    }
    ${FloorButtonContent}{
      background-color: ${COLORS.Primary50};    
    }
  }
  
`;


interface Props {
  onFloorSelected: (floor: string) => void;
}

export const FloorNavigation: React.FunctionComponent<Props> = ({ onFloorSelected }) => {

  const { t } = useTranslation();

  const currentProject = useSelector(selectCurrentProject);
  const currentFloor = useSelector(selectCurrentFloor);
  const isLargeScreen = useMediaMatch(BREAKPOINTS.LARGE.replace('@media ', ''));
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));


  const lastFloor = currentProject!.floors[currentProject!.floors.length - 1].floor.floor === currentFloor?.floor;
  const firstFloor = currentProject!.floors[0].floor.floor === currentFloor?.floor;
  const currentIndex = currentProject!.floors.findIndex(a => a.floor.floor === currentFloor?.floor);

  const nextFloor = currentProject!.floors[currentIndex! + 1]?.floor.floor;
  const prevFloor = currentProject!.floors[currentIndex! - 1]?.floor.floor;

  const count = isMediumScreen ? 2 : 1;
  let itemsFrom = Math.max(currentIndex - count, 0);
  let itemsTo = Math.min(currentIndex + count, currentProject!.floors.length - 1);

  itemsTo += itemsFrom - (currentIndex - count);
  itemsFrom -= currentIndex + count - Math.min(currentIndex + count, currentProject!.floors.length - 1);

  const floors = currentProject!.floors.slice(itemsFrom, itemsTo + 1).reverse();

  return (
    <Wrapper>
      <IconButton action={() => onFloorSelected(nextFloor)}
        icon={isLargeScreen ? <ChevronUp /> : <ChevronRight />}
        disabled={lastFloor} />

      {floors.map(floor => (
        <FloorButton key={floor.floor.floor}
          onClick={() => onFloorSelected(floor.floor.floor)}
          className={floor.floor.floor === currentFloor?.floor ? 'active' :
            floor.floor.floor === nextFloor || floor.floor.floor === prevFloor ? 'adj' : ''}>
          <FloorButtonContent>
            <FloorButtonMainText>
              {floor.floor.floor}
            </FloorButtonMainText>
            <FloorButtonNoteText>
              {t(`FloorType.${floor.floor.type}`)}
            </FloorButtonNoteText>
          </FloorButtonContent>
        </FloorButton>
      ))}

      <IconButton action={() => onFloorSelected(prevFloor)}
        icon={isLargeScreen ? <ChevronDown /> : <ChevronLeft />}
        disabled={firstFloor} />
    </Wrapper>
  );
};