import React from 'react';
import {useSelector} from 'react-redux';
import {selectHomePageData} from '../../../utils';
import {Trans, useTranslation} from 'react-i18next';
import styled from '@emotion/styled/macro';
import {HeaderText, ParagraphText} from '../styles';
import {COLORS} from '../../../utils/styles/colors';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import {useMediaMatch} from 'rooks';
import sanity from '../../../utils/sanity';
import {ComponentLStyling, ComponentMStyling, TextStyle} from '../../../utils/styles/typography';
const Background = styled.div`
  background-color: ${COLORS.White};
  padding: 16px 16px;
  ${BREAKPOINTS.MEDIUM}{
    padding: 16px 32px;
  }

  ${BREAKPOINTS.MEDIUM}{
    padding: 28px 32px;
  }

  ${BREAKPOINTS.XLARGE}{
    padding: 56px 56px;
  }
`;


const ImageContainer = styled.div`
  display: flex;
  img{
    max-width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  ${BREAKPOINTS.LARGE}{
    max-width: 274px;
    align-items: flex-start;
    gap: 56px;
  }
`;


const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 32px;
  
  ${ContentContainer}{
    i {
      color: ${COLORS.MoodTwo100};
    }
  }

  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;


const Legend = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 16px;
  ${ComponentMStyling(TextStyle.Regular, COLORS.Neutral700)}
  
  ${BREAKPOINTS.LARGE}{
    ${ComponentLStyling(TextStyle.Regular, COLORS.Neutral700)}
    gap: 24px;
  }
  li:nth-of-type(1)::marker{
    color: ${COLORS.MoodTwo500};
  }
  li:nth-of-type(2)::marker{
    color: ${COLORS.MoodThree500};
  }

`;


export const SunlightSection = () => {

  const {t} = useTranslation();
  const data = useSelector(selectHomePageData);

  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const isXLargeScreen = useMediaMatch(BREAKPOINTS.XLARGE.replace('@media ', ''));

  const height = isXLargeScreen
                 ? 512
                 : isMediumScreen
                   ? 456
                   : 412;

  return <Background><Wrapper>
    <ImageContainer>
      <img alt={data!.sunlight.altText}
           height={height}
           src={sanity.imageUrlBuilder(data!.sunlight!.asset._ref).url()}/>
    </ImageContainer>
    <ContentContainer>
      <HeaderText>
        <Trans ns={'Common'} i18nKey={'HomePageSunlightHeader'}>
          <i>მზე</i> შენს სახლში
        </Trans>
      </HeaderText>
      <ParagraphText>
        <Trans ns={'Common'} i18nKey={'HomePageSunlightDescription'}>
          დააკვირდი დიაგრამაზე აღნიშნულ საათებს. წერტილებით აღნიშნულია მზის პოზიცია
        </Trans>
      </ParagraphText>
    </ContentContainer>
  </Wrapper></Background>;
};