import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {BREAKPOINTS} from '../../utils/styles/breakpoints';
import {COLORS} from '../../utils/styles/colors';
import {
  HeadlineLStyling,
  HeadlineMStyling, HeadlineSStyling, ParagraphMStyling,
  ParagraphSStyling,
  ParagraphXSStyling,
  TextStyle,
} from '../../utils/styles/typography';


const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  
  ${BREAKPOINTS.MEDIUM}{
    gap: 12px;
  }
  
  ${BREAKPOINTS.XLARGE}{
    gap: 16px;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 66px;
  background: ${COLORS.Primary100};
  
  ${BREAKPOINTS.MEDIUM}{
    height: 76px;
  }
  
  ${BREAKPOINTS.XLARGE}{
    height: 92px;
  }

`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  padding: 4px;
  
  ${BREAKPOINTS.MEDIUM}{
    gap: 4px;
  }
`;

const TimerText = styled.div`
  ${HeadlineSStyling(TextStyle.Medium, COLORS.Primary300)}
  ${BREAKPOINTS.MEDIUM}{
    ${HeadlineMStyling(TextStyle.Medium, COLORS.Primary300)}
  }
  ${BREAKPOINTS.LARGE}{
    ${HeadlineLStyling(TextStyle.Medium, COLORS.Primary300)}
  }
`;

const UnitText = styled.div`
  ${ParagraphXSStyling(TextStyle.Regular, COLORS.Neutral700)}
  ${BREAKPOINTS.MEDIUM}{
    ${ParagraphSStyling(TextStyle.Regular, COLORS.Neutral700)}
  }
  ${BREAKPOINTS.LARGE}{
    ${ParagraphMStyling(TextStyle.Regular, COLORS.Neutral700)}
  }
`;

interface Props {
  date: Date;
}

export const CountDown = ({date}: Props) => {

  const {t} = useTranslation();
  const [timeLeft, setTimeLeft] = useState<{ days: number, hours: number, minutes: number, seconds: number }>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculate = () => {
    if (moment(date).isBefore(moment())) {
      return;
    }
    let current = moment();
    const day = moment(date).diff(current, 'days');
    current = current.add(day, 'days');
    const hour = moment(date).diff(current, 'hours');
    current = current.add(hour, 'hours');
    const minute = moment(date).diff(current, 'minutes');
    current = current.add(minute, 'minutes');
    const second = moment(date).diff(current, 'seconds');

    setTimeLeft({days: day, hours: hour, minutes: minute, seconds: second});
  };

  useEffect(() => {
    calculate();

    const interval = setInterval(() => calculate(), 1000);

    return () => clearInterval(interval);
  }, [date]);

  return (
    <>
      {
        timeLeft &&
        <Wrapper>
          <TimeContainer>
            <TimerText>
              {timeLeft.days}
            </TimerText>
            <UnitText>
              {t('დღე')}
            </UnitText>
          </TimeContainer>
          <Divider/>

          <TimeContainer>
            <TimerText>
              {timeLeft.hours}
            </TimerText>
            <UnitText>
              {t('საათი')}
            </UnitText>
          </TimeContainer>
          <Divider/>

          <TimeContainer>
            <TimerText>
              {timeLeft.minutes}
            </TimerText>
            <UnitText>
              {t('წუთი')}
            </UnitText>
          </TimeContainer>
          <Divider/>

          <TimeContainer>
            <TimerText>
              {timeLeft.seconds}
            </TimerText>
            <UnitText>
              {t('წამი')}
            </UnitText>
          </TimeContainer>
        </Wrapper>
      }
    </>
  );
};

