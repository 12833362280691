import React from 'react';

export const shouldHandleButtonPress = (e: React.KeyboardEvent) => {
  return e.key === 'Enter' || e.key === ' ';
};

export const defaultOnMouseDownHandler = (e: React.MouseEvent<any>) => {
  e.preventDefault();
  if (document.activeElement !== e.currentTarget) {
    //@ts-ignore
    document?.activeElement?.blur();
  }
};
