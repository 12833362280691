import React from 'react';
import styled from "@emotion/styled";
import {COLORS} from "../../utils/styles/colors";
import {ComponentMStyling, TextStyle} from "../../utils/styles/typography";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  
  background-color: ${COLORS.Neutral50};
  border-radius: 20px;
  height: 40px;
  
  cursor: pointer;
  
  transition: background-color 0.2s ease-in-out;
  
  &:hover {
    background-color: ${COLORS.Primary50};
  }
`;

const ItemWrapper = styled.button`
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  transition: background-color 0.2s ease-in-out;

  ${ComponentMStyling(TextStyle.Medium)}

  cursor: pointer;
  color: ${COLORS.Primary700};
  background-color: transparent;
  
  &.selected{
    color: ${COLORS.White};
    background-color: ${COLORS.Primary500};
  }
`;

interface Props {
  value: string;
  onChange: (value: string) => void;
  items: { value: string, label: any }[];
}

export const Toggle = ({value, onChange, items}: Props) => {

  return (
    <Wrapper>
      {items.map((item) => <ItemWrapper key={item.value}
                                        className={value === item.value ? 'selected' : ''}
                                        onClick={() => onChange(item.value)}>
        {item.label}
      </ItemWrapper>)}
    </Wrapper>
  )
}