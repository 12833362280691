import React, {useEffect, useRef, useState} from 'react';
import styled from '@emotion/styled';
import {useSelector} from 'react-redux';
import {Flat, FlatStates, FloorTypes, selectCurrentFloor} from '../../../utils';
import {useTranslation} from 'react-i18next';
import {useDimensionsRef, useMediaMatch} from 'rooks';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import sanity from '../../../utils/sanity';
import InlineSVG from 'react-inlinesvg';
import {COLORS} from '../../../utils/styles/colors';
import {FlatOverlay} from './FlatOverlay';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';
import {HyperLink} from '../../../components/HyperLink/HyperLink';
import {PDF} from '../../../components/Icons/Icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;

  
  box-sizing: border-box;
  ${BREAKPOINTS.LARGE}{
    justify-content: flex-start;
    padding: 0;
    width: unset;
  }
`;

const FloorContainer = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: 456px;
  min-height: 970px;
  
  ${BREAKPOINTS.LARGE}{
    min-height: unset;
    max-width: unset;
  }

  svg {
    [class]{
      cursor: pointer;
      fill: transparent;
      &.available {
        &:hover{
          transition: fill 0.2s ease-in-out;
          fill: ${COLORS.Primary200};
          opacity: 0.5;
        }
        &:active{
          fill: ${COLORS.Primary300};
          opacity: 0.5;
        }
      }
      &.sold{
        cursor: default;
        fill: rgba(0, 0, 0, 0.3);
      }
      &.reserved{
        cursor: default;
        fill: rgba(0, 0, 0, 0.3);
      }
    }
  }
  
  .parking & {
    svg {
      [class]{
        &.sold{
          cursor: default;
          fill: rgba(0, 0, 0, 0.75);
        }
        &.reserved{
          cursor: default;
          fill: rgba(0, 0, 0, 0.75);
        }
      }
    }
  }
`;

const DownloadLinkWrapper = styled.div`
  align-self: flex-start;
  margin-top: 32px;
  
  ${BREAKPOINTS.LARGE}{
    margin-bottom: -68px;
    margin-top: 48px;
    align-self: flex-end;
  }
`;

interface Props {
  onFlatSelected: (flat: number) => void;
}

export const FlatSelector: React.FunctionComponent<Props> = ({
                                                               onFlatSelected,
                                                             }) => {
  const {t} = useTranslation();
  const [ref, dimensions] = useDimensionsRef();
  const containerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGElement>(null);
  const [loading, setLoading] = useState(true);
  const [flatOverlays, setFlatOverlays] = useState<{ flat: Flat, container: SVGSVGElement }[]>([]);
  const [floorSvg, setFloorSvg] = useState<string>('');

  const currentFloor = useSelector(selectCurrentFloor);

  const isLargeScreen = useMediaMatch(BREAKPOINTS.LARGE.replace('@media ', ''));

  const url = sanity.imageUrlBuilder(isLargeScreen
    ? currentFloor!.image.asset._ref
    : currentFloor!.imageRotated.asset._ref).url();


  const floorClickHandler = (event: React.MouseEvent) => {
    if (currentFloor?.type != FloorTypes.Apartments) return;

    const container = (event.target as Element).classList[0];

    if (container) {
      const flat = currentFloor?.flats.find(a => a.container === container);
      flat && flat.flat.state == FlatStates.Available && onFlatSelected(+flat.flat.number);
    }
  };

  useEffect(() => setLoading(!svgRef.current), [svgRef.current]);

  useEffect(() => {
    if (loading || url != floorSvg) {
      setFlatOverlays([]);
      return;
    }

    const container = containerRef.current;
    const containers = Array.from(container!.querySelectorAll('svg [class]'));

    const overlays = currentFloor?.flats?.map(flat => ({
      flat: flat.flat,
      container: containers.find(a => a.classList.contains(flat.container)) as SVGSVGElement,
    })).filter(a => !!a.container)

    overlays?.forEach(a => {
      a.container.classList.remove(
        FlatStates.Sold.toLowerCase(),
        FlatStates.Reserved.toLowerCase(),
        FlatStates.Available.toLowerCase(),
      );
      a.container.classList.add(a.flat?.state?.toLowerCase());
    });

    setFlatOverlays(overlays ?? []);
  }, [currentFloor, loading, dimensions?.width, dimensions?.height, floorSvg, url]);

  return (
    <Wrapper ref={containerRef} className={currentFloor?.type?.toLowerCase()}>
      <FloorContainer ref={ref} onClick={floorClickHandler}>
        {!!dimensions?.width &&
            <InlineSVG innerRef={svgRef}
                       onLoad={() => {
                         setLoading(false);
                         setFloorSvg(url)
                       }}
                       cacheRequests={false}
                       loader={<LoadingIndicator/>}
                       src={url}
                       width={dimensions.width}/>}
        {
          flatOverlays.map(a => <FlatOverlay key={a.flat.number}
                                             flat={a.flat}
                                             width={dimensions?.width}
                                             loading={loading}
                                             container={a.container}/>)
        }
      </FloorContainer>

      <DownloadLinkWrapper>
        <HyperLink label={t('გადმოწერე სართულის გეგმა')}
                   icon={<PDF/>}
                   href={currentFloor!.document}
                   target={'_blank'}/>
      </DownloadLinkWrapper>
    </Wrapper>
  );

};