import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {Global} from '@emotion/react';

import App from './App';
import store from './utils/store';
import GlobalStyles from './utils/globalSyles';

import './utils/i18n';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ReactModal from 'react-modal';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

ReactModal.setAppElement('#root');

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Global styles={GlobalStyles}/>
      <App/>
    </BrowserRouter>
  </Provider>,
);
