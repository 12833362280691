import React from 'react';
import styled from '@emotion/styled';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectCurrentProject, selectHomePageData} from '../../../utils';
import {useMediaMatch} from 'rooks';
import {HeaderText, ParagraphText} from '../styles';
import sanity from '../../../utils/sanity';
import {COLORS} from '../../../utils/styles/colors';
import {
  ComponentLStyling,
  ComponentMStyling,
  HeadlineSStyling,
  HeadlineXSStyling,
  TextStyle,
} from '../../../utils/styles/typography';

const Background = styled.div`
  background-color: ${COLORS.Primary50};
  padding: 16px 16px;
  ${BREAKPOINTS.MEDIUM}{
    padding: 16px 32px;
  }

  ${BREAKPOINTS.MEDIUM}{
    padding: 28px 32px;
  }

  ${BREAKPOINTS.XLARGE}{
    padding: 56px 56px;
    padding-bottom:28px
  }
`;


const ParkingPrice = styled.div`
  text-align: center;
  ${HeadlineXSStyling(TextStyle.Medium, COLORS.Primary300)}
  
  ${BREAKPOINTS.LARGE}{
    ${HeadlineSStyling(TextStyle.Medium, COLORS.Primary300)}
    text-align: left;
  }
`;

const ParkingText = styled.div`
  text-align: center;
  ${ComponentMStyling(TextStyle.Regular, COLORS.Neutral700)}
  
  ${BREAKPOINTS.LARGE}{
    ${ComponentLStyling(TextStyle.Regular, COLORS.Neutral700)}
    text-align: left;
  }
`;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const ParkingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  
  ${BREAKPOINTS.LARGE}{
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ImageContainer = styled.div`
    img{
        max-width: 100%;
    }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  
  ${BREAKPOINTS.LARGE}{
    max-width: 274px;
    align-items: flex-start;
  }
`;

export const ParkingSection = () => {

  const {t} = useTranslation();
  const data = useSelector(selectHomePageData);

  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const isXLargeScreen = useMediaMatch(BREAKPOINTS.XLARGE.replace('@media ', ''));
  const currentProject = useSelector(selectCurrentProject);

  const height = isXLargeScreen
                 ? 512
                 : isMediumScreen
                   ? 456
                   : 412;

  return <Background><Wrapper>
    <ContentContainer>
      <HeaderText>
        <Trans ns={'Common'} i18nKey={'HomePageParkingHeader'}>
          <i> პარკინგი</i> მიწის ქვეშ.
        </Trans>
      </HeaderText>
      <ParkingBox>
        <ParkingPrice>
          {currentProject?.parkingPrice?.price}$
        </ParkingPrice>
        <ParkingText>
          {t('ერთიანი ანგარიშსწორება')}
        </ParkingText>
      </ParkingBox>
      <ParkingBox>
        <ParkingPrice>
          {currentProject?.parkingPrice?.loanPrice}$
        </ParkingPrice>
        <ParkingText>
          {t('შიდა განვადება')}
        </ParkingText>
      </ParkingBox>
    </ContentContainer>
    <ImageContainer>
      <img alt={data?.parking.altText}
           src={sanity.imageUrlBuilder(data!.parking!.asset._ref).height(height).format('png').fit('scale').url()}/>

    </ImageContainer>
  </Wrapper></Background>;
};