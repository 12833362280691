import React, {CSSProperties, useEffect, useState} from 'react';
import {Flat, FlatStates, FloorTypes, selectCurrentFloor} from '../../../utils';
import styled from '@emotion/styled';
import {useTranslation} from 'react-i18next';
import {BREAKPOINTS} from '../../../utils/styles/breakpoints';
import {ComponentMStyling, TextStyle} from '../../../utils/styles/typography';
import {COLORS} from '../../../utils/styles/colors';
import {useSelector} from "react-redux";
import {Button} from "../../../components/Button/Button";

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  user-select: none;

  ${ComponentMStyling(TextStyle.Medium, COLORS.White)}
  text-overflow: ellipsis;
  
  &.available {
    ${BREAKPOINTS.LARGE}{
      display: none;
      &.show{
        display: flex;
      }
    }
  }
`;

interface Props {
  flat: Flat;
  container: SVGSVGElement;
  width: number | undefined;
  loading: boolean;
}

export const FlatOverlay: React.FunctionComponent<Props> = ({flat, container, width, loading}) => {

  const {t} = useTranslation();

  const [isVisible, setIsVisible] = useState(false);
  const currentFloor = useSelector(selectCurrentFloor);

  const [styles, setStyles] = useState<CSSProperties | undefined>(undefined);

  useEffect(() => {
    if (loading || !container.viewportElement || !container.viewportElement.clientWidth) {
      setStyles({display: 'none'});
      return;
    }

    const viewport = container.viewportElement! as SVGSVGElement;

    const xMultiplier = viewport.clientWidth / viewport.getBBox().width;
    const yMultiplier = viewport.clientHeight / viewport.getBBox().height;

    const rect = container.getBBox();

    setStyles({
      display: undefined,
      left: rect.x * xMultiplier,
      top: rect.y * yMultiplier,
      width: rect.width * xMultiplier,
      height: rect.height * yMultiplier,
    });

    const onMouseEnter = () => setIsVisible(true);
    const onMouseLeave = () => setIsVisible(false);
    container.matches(':hover') && setIsVisible(true);

    container.addEventListener('mouseenter', onMouseEnter);
    container.addEventListener('mouseleave', onMouseLeave);

    return () => {
      container.removeEventListener('mouseenter', onMouseEnter);
      container.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [flat, container, width, loading]);

  return (
    <>
      {
        styles &&
        <Wrapper style={styles} className={`${currentFloor?.type?.toLowerCase() || ''} ${flat?.state?.toLowerCase() || ''} ${isVisible ? 'show' : 'hide'}`}>
          {
              currentFloor?.type === FloorTypes.Apartments &&
              <>
                {
                    flat.state === FlatStates.Available &&
                    <Button style={{maxWidth: 'fit-content', minWidth: 'max-content'}}
                            action={() => {}}
                            label={flat.area + ' m²'}/>
                }
                {
                    flat.state && flat.state !== FlatStates.Available &&
                    t('FlatStates.' + flat.state)
                }
              </>
          }
        </Wrapper>
      }
    </>
  );
};