import React from 'react';
import styled from '@emotion/styled';
import {COLORS} from '../../utils/styles/colors';
import {
  ComponentLStyling,
  ComponentMStyling, ComponentSStyling, ComponentXSStyling,
  HeadlineLStyling,
  HeadlineMStyling,
  HeadlineSStyling, HeadlineXLStyling, ParagraphMStyling, ParagraphSStyling,
  TextStyle,
} from '../../utils/styles/typography';
import {BREAKPOINTS} from '../../utils/styles/breakpoints';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Floor, selectCurrentProject} from '../../utils';
import {useContentLoader} from '../Common/ContentLoader';
import {BackButton} from '../../components/BackButton/BackButton';
import {FloorSelector} from './components/FloorSelector';
import {BuildingNavigation} from './components/BuildingNavigation';
import {HyperLink} from '../../components/HyperLink/HyperLink';
import {Location} from '../../components/Icons/Icons';
import {useMediaMatch} from 'rooks';
import {CountDown} from '../Common/CountDown';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  ${BREAKPOINTS.MEDIUM}{
    padding: 0 32px;
  }

  ${BREAKPOINTS.XLARGE}{
    padding: 0 56px;
  }
  
  margin-bottom: 56px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const Header = styled.div`

  display: flex;
  flex-direction: column;
  gap: 2px;
  
  align-items: center;

  ${BREAKPOINTS.LARGE}{
    align-items: flex-start;
  }

`;

const Title = styled.div`
  ${HeadlineMStyling(TextStyle.SemiBold, COLORS.Black)}
  
  ${BREAKPOINTS.MEDIUM}{
    ${HeadlineLStyling(TextStyle.SemiBold, COLORS.Black)}
  }
  
  ${BREAKPOINTS.XLARGE}{
    ${HeadlineXLStyling(TextStyle.SemiBold, COLORS.Black)}
  }
`;

const Description = styled.div`
  ${ComponentXSStyling(TextStyle.Regular, COLORS.Black)}
  
  ${BREAKPOINTS.MEDIUM}{
    ${ComponentSStyling(TextStyle.Regular, COLORS.Black)}
  }
`;


const BuildingContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 640px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  order: 1;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  order: 2;
  ${BREAKPOINTS.LARGE}{
    gap: 40px;
    flex: 1;
    order: 0;
  }
`;

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;  
  ${BREAKPOINTS.LARGE}{
    align-items: flex-start;
    flex-direction: row;
  }
`;

const Subtitle = styled.div`
  ${ComponentMStyling(TextStyle.SemiBold, COLORS.Neutral900)}
  
  ${BREAKPOINTS.XLARGE}{
    ${ComponentLStyling(TextStyle.SemiBold, COLORS.Neutral900)}
  }
`;

const Paragraph = styled.div`
  ${ParagraphSStyling(TextStyle.Regular, COLORS.Neutral700)}
  max-width: 60ch;
  text-align: center;
  
  ${BREAKPOINTS.LARGE}{
    text-align: left;
  }

  ${BREAKPOINTS.LARGE}{
    ${ParagraphMStyling(TextStyle.Regular, COLORS.Neutral700)}
  }
`;


const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  
  ${BREAKPOINTS.LARGE}{
    align-items: flex-start;
  }
`;

export const ProjectPage = () => {

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const currentProject = useSelector(selectCurrentProject);

  const [activeFloor, setActiveFloor] = useState<Floor | undefined>(undefined);

  const [project] = useContentLoader();
  const isLargeScreen = useMediaMatch(BREAKPOINTS.LARGE.replace('@media ', ''));


  useEffect(() => setActiveFloor(currentProject?.floors[currentProject?.floors.length - 1]?.floor), [currentProject]);


  const onFloorSelected = (floor: string) => {
    navigate(`/project/${project}/${floor}`);
  };

  return (
    <>
      {
        !!currentProject &&
        <Wrapper>
          <BackButton label={t('მთავარზე დაბრუნება')}
                      action={() => navigate(`/`)}/>
          <ContentContainer>

            {
              !isLargeScreen &&
              <Header>
                <Title>{t('შენობის გვერდი')}</Title>
                <Description>{t('აირჩიე სართული')}</Description>
              </Header>
            }
            <ProjectContainer>
              <BuildingContainer>
                <BuildingNavigation onActiveFloorChanged={(a) => setActiveFloor(a)}
                                    activeFloor={activeFloor}/>
                <FloorSelector onFloorSelected={onFloorSelected}
                               onActiveFloorChanged={setActiveFloor}
                               activeFloor={activeFloor}/>
              </BuildingContainer>
              <DescriptionContainer>
                {
                  isLargeScreen &&
                  <Header>
                    <Title>{t('შენობის გვერდი')}</Title>
                    <Description>{t('აირჩიე სართული')}</Description>
                  </Header>
                }

                <ParagraphContainer>
                  <Subtitle>{t('დასრულებამდე დარჩენილია')}</Subtitle>
                  <CountDown date={currentProject!.endDate}/>
                </ParagraphContainer>

                <ParagraphContainer>
                  <Subtitle>{t('პროექტის შესახებ')}</Subtitle>
                  <Paragraph>{t(
                    'შენობას გააჩნია ფეხბურთისა და კალათბურთის მოედანი. რეკრეაციული ზონა, რომელიც ფარავს 5000 კვადრატულ მეტრს. ')}</Paragraph>
                  <Paragraph>{t(
                    'ავტოსადგომისთვის გამოყოფილია 3 სართული. პირველი სართული გამოყოფილია კომერციული დანიშნულებისთვის')}</Paragraph>
                </ParagraphContainer>

                <ParagraphContainer>
                  <Subtitle>{t('მისამართი')}</Subtitle>
                  <HyperLink
                    href={currentProject?.location}
                    target={'_blank'}
                    label={currentProject?.address[i18n.language] || ''}
                    icon={<Location/>}/>
                </ParagraphContainer>


              </DescriptionContainer>
            </ProjectContainer>
          </ContentContainer>

        </Wrapper>
      }
    </>
  );
};