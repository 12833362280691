export const COLORS = {
  Black: '#000000',
  White: '#FFFFFF',

  Primary900: '#00227D',
  Primary800: '#003291',
  Primary700: '#003C9D',
  Primary600: '#0045A8',
  Primary500: '#004CB1',
  Primary400: '#0065BC',
  Primary300: '#4A80C8',
  Primary200: '#82A3D9',
  Primary100: '#B4C7EA',
  Primary50: '#E2E9F6',

  Neutral900: '#19191B',
  Neutral800: '#39393B',
  Neutral700: '#57575A',
  Neutral600: '#6B6B6D',
  Neutral500: '#939396',
  Neutral400: '#B3B3B6',
  Neutral300: '#D7D7DA',
  Neutral200: '#E7E7EA',
  Neutral100: '#F1F1F4',
  Neutral50: '#F8F8FB',

  Positive900: '#14532D',
  Positive800: '#166534',
  Positive700: '#15803D',
  Positive600: '#16A34A',
  Positive500: '#22C55E',
  Positive400: '#4ADE80',
  Positive300: '#86EFAC',
  Positive200: '#BBF7D0',
  Positive100: '#DCFCE7',
  Positive50: '#F0FDF4',

  Invalid900: '#7F1D1D',
  Invalid800: '#991B1B',
  Invalid700: '#B91C1C',
  Invalid600: '#DC2626',
  Invalid500: '#EF4444',
  Invalid400: '#F87171',
  Invalid300: '#FCA5A5',
  Invalid200: '#FECACA',
  Invalid100: '#FEE2E2',
  Invalid50: '#FEF2F2',
  
  MoodOne50: '#D8EADD',
  MoodOne300: '#2B854C',
  MoodOne500: '#005A07',
  
  MoodTwo100: '#EAD7B4',
  MoodTwo500: '#CA8000',
  
  MoodThree500: '#10A4BE',
};

