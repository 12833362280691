import React from 'react';
import styled from '@emotion/styled/macro';
import {COLORS} from '../../utils/styles/colors';
import {SHADOWS} from '../../utils/styles/shadows';
import {ComponentMStyling, ComponentSStyling, ComponentXSStyling, TextStyle} from '../../utils/styles/typography';
import {BREAKPOINTS} from '../../utils/styles/breakpoints';

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  
  box-sizing: border-box;
  min-height: 40px;
  border-radius: 20px;
  padding: 10px 20px;
  
  ${ComponentSStyling(TextStyle.Medium, null)}
 
  ${BREAKPOINTS.MEDIUM}{
    gap: 10px;
    min-height: 44px;
    padding: 10px 24px;
    border-radius: 22px;
    ${ComponentMStyling(TextStyle.Medium, null)}
  }
`;

const Container = styled.button`
  display: flex;
  flex: 1;
  min-height: 48px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  background: none;
  padding: 0;
  cursor: pointer;
  
  &.primary{
    ${ContentContainer}{
      background-color: ${COLORS.Primary500};
      color: ${COLORS.White};
    }
  }
  
  &.primary:hover{
    ${ContentContainer}{
      background-color: ${COLORS.Primary700};
      box-shadow: ${SHADOWS.ShadowCentered};
    }
  }

  &.primary:active{
    ${ContentContainer}{
      background-color: ${COLORS.Primary900};
      box-shadow: none;
    }
  }


  &.secondary{
    ${ContentContainer}{
      border: 1px solid ${COLORS.Primary500};
      color: ${COLORS.Primary500};
    }
  }
  
  &.secondary:hover{
    ${ContentContainer}{
      border: 1px solid ${COLORS.Primary700};
      color: ${COLORS.Primary700};
      box-shadow: ${SHADOWS.ShadowCentered};
    }
  }

  &.secondary:active{
    ${ContentContainer}{
      border: 1px solid ${COLORS.Primary900};
      color: ${COLORS.Primary900};
      box-shadow: none;
    }
  }

  &.filled{
    ${ContentContainer}{
      background-color: ${COLORS.Primary50};
      color: ${COLORS.Primary700};
    }
  }

  &.filled:hover{
    ${ContentContainer}{
      background-color: ${COLORS.Primary100};
      color: ${COLORS.Primary900};
    }
  }

  &.filled:active{
    ${ContentContainer}{
      background-color: ${COLORS.Primary200};
      color: ${COLORS.Primary900};
    }
  }
  
  &:focus{
   outline-offset: 4px; 
  }
  
  &:disabled{
   cursor: not-allowed;
   pointer-events: none;
   ${ContentContainer}{
     opacity: 50%;
   }
  }
`;

interface ButtonProps {
  variant?: 'primary' | 'secondary' | 'filled';
  action: () => void;
  icon?: React.ReactNode;
  label: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const Button: React.FunctionComponent<ButtonProps> = ({
  variant = 'primary',
  action,
  label,
  icon,
  disabled,
  style,
}) => {
  return (
    <Container className={variant} onClick={action} disabled={disabled} style={style}>
      <ContentContainer>
        <span>{label}</span>
        {icon && <span>{icon}</span>}
      </ContentContainer>
    </Container>
  );
};